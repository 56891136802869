//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import sharedVariables from '~/util/shared-variables.js';
import ArrowRight from '~/static/svg/arrow-right.svg';
import ArrowLeft from '~/static/svg/arrow-left.svg';

import gtmMixin from '~/mixins/gtm';
import algoliaRecommend from '~/mixins/algoliaRecommend';

export default {
  mixins: [gtmMixin, algoliaRecommend],
  components: {
    ArrowLeft,
    ArrowRight,
  },
  props: {
    time: {
      type: String,
      default: '4000',
    },
    title: {
      type: String,
      default: 'Trending Products',
    },
    threshold: {
      type: Number,
      default: 70,
    },
    noBorder: {
      type: Boolean,
      deafult: false,
    },
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'users/getIsLoggedIn',
      isCustomerExcludingGst: 'users/isCustomerExcludingGst',
    }),
    canShowCarousel() {
      return (
        this.$config.featureToggle.toggles.enableHomeTrendingCarousel ?? false
      );
    },
    trendingCarouselThreshold() {
      return this.$config.trending.threshold ?? 40;
    },
    showPriceGstLabel() {
      if (this.isUserLoggedIn && this.isCustomerExcludingGst) {
        return this.$t('related_products.excludes_gst');
      }

      return this.$t('related_products.includes_gst');
    },
  },
  data() {
    return {
      isLoaded: false,
      products: [],
      options: { autoplaySpeed: undefined },
      // productsRaw: [],
      recommendationsRaw: [],
    };
  },
  async mounted() {
    this.setSliderOptions();
    // Retrieve Recommendations from Algolia,
    this.products = await this.getAlgoliaRecommendationsList();
    this.isLoaded = true;
  },
  // vue-slick-carousel autoplay is causing error (keep refering to undefined variable)
  // we have to manage the autoplay in this component with the updated() and beforeDestroy()
  updated() {
    if (this.isLoaded && this.$refs['slick-carousel']) {
      // this.$refs['slick-carousel'].autoplay = true; // turn on autoplay
      this.slickCarousel = this.$refs['slick-carousel']; // keep a handle on this carousel
    }
  },
  beforeDestroy() {
    if (this.isLoaded && this.slickCarousel) {
      this.slickCarousel.pause(); // stop the autoplay on this carousel before destroying
    }
  },
  methods: {
    environmentPdpUrl(product) {
      const envBaseUrl = this.$config.baseUrl;
      return `${envBaseUrl}/p/${product.slug}/`;
    },
    async getAlgoliaRecommendationsList() {
      const processedList = [];

      const params = {
        threshold: this.trendingCarouselThreshold,
        listLimit: 24,
      };

      const results = await this.getAlgoliaRecommendations('trending', params);

      if (results.length > 0) {
        this.recommendationsRaw = results;
        //Fill Related Products with Recommendations up to List Limit
        for (let i = 0; i < results.length; i++) {
          if (i >= params.listLimit) break;
          processedList.push(results[i]);
        }
      }

      return processedList;
    },
    setSliderOptions() {
      const autoplaySpeed = parseInt(this.time);
      this.options = {
        dots: true,
        infinite: false,
        lazyLoad: true,
        initialSlide: 0,
        speed: 1000,
        autoplay: false,
        slidesToScroll: 4,
        slidesToShow: 4,
        autoplaySpeed: autoplaySpeed,
        pauseOnHover: true,
        pauseOnDotHover: true,
        variableWidth: true,
        swipeToSlide: true,
        arrows: true,
        touchThreshold: 3,
        responsive: [
          {
            breakpoint: sharedVariables.sm_max,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1,
            },
          },
        ],
      };
    },
  },
};
