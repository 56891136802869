import { render, staticRenderFns } from "./loginPopup.vue?vue&type=template&id=eab607ae&scoped=true&"
import script from "./loginPopup.vue?vue&type=script&lang=js&"
export * from "./loginPopup.vue?vue&type=script&lang=js&"
import style0 from "./loginPopup.vue?vue&type=style&index=0&id=eab607ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eab607ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginPopupHeader: require('/home/node/frontend/components/includes/loginPopupHeader.vue').default,BdLoginPopup: require('/home/node/frontend/node_modules/@bowenstimber/designsystem/components/tradeportal/BdLoginPopup.vue').default,BdLoggedInPopup: require('/home/node/frontend/node_modules/@bowenstimber/designsystem/components/tradeportal/BdLoggedInPopup.vue').default,MyAccountSidebar: require('/home/node/frontend/components/my-account/shared/MyAccountSidebar.vue').default})
