import { cloneObject } from './helper';

/**
 * Truncate and round up a number to the specified decimal place
 * @param {number} number - value to be rounded up
 * @param {number} decimalPlace - number of decimal places to round
 * @returns {number} - rounded value
 */
export function roundUp(number, decimalPlace) {
  const power = Math.pow(10, decimalPlace);
  return Math.ceil(number * power) / power;
}

/**
 * Truncate and round a number to the specified decimal place
 * @param {number} number - value to be rounded (up or down)
 * @param {number} decimalPlace - number of decimal places to round
 * @returns {number} - rounded value
 */
export function round(number, decimalPlace) {
  return parseFloat(number.toFixed(decimalPlace));
}

/**
 * Update Pricing with new unit price
 * @param {object} pricing - SKU price query response object
 * @param {number} unitPrice - current unit price
 * @returns {object} - updated pricing object
 */
export function updatePricingWithNewUnitPrice(pricing, unitPrice) {
  const newPricing = cloneObject(pricing);
  newPricing.unitPrice = unitPrice;
  newPricing.linePrice = round(newPricing.unitPrice * newPricing.qty, 2);
  if (newPricing.incGst) {
    newPricing.lineGst = round(newPricing.linePrice / 11, 2);
  }

  return newPricing;
}

/**
 * Apply discount to pricing
 * @param {object} pricing - SKU price query response object
 * @param {object} discount - discount object
 * @returns {object} - updated pricing object
 */
export function applyDiscountToPricing(pricing, discount) {
  const newPricing = cloneObject(pricing);

  newPricing.hasDiscount = true;

  let discountLinePrice = round(
    discount.unitPriceFromBulkBuy * newPricing.qty,
    2
  );
  let discountLineGst = round(discountLinePrice / 11, 2);

  newPricing.discount = {
    unitPrice: discount.unitPriceFromBulkBuy,
    unitPriceExcGst: discount.unitPriceExcGst,
    quantityBreak: discount.quantityBreak,
    linePrice: discountLinePrice,
    lineGst: discountLineGst,
  };

  return newPricing;
}

/**
 * Check for and apply bulk buy pricing if available on current variant,
 * but only do so if discounted pricing is actually cheaper than RRP
 *
 * @param {object} pricing - SKU price query response object
 * @param {object} webProduct - selected web product state object
 * @param {string} SKU - sku string for this variant
 * @returns {object} - updated pricing object
 */
export function updatePricingIfApplyBulkBuy(pricing, webProduct, SKU) {
  if (
    !SKU ||
    !webProduct ||
    !webProduct.variations ||
    webProduct.variations.length < 1
  ) {
    return cloneObject(pricing);
  }

  const found = webProduct.variations.find(variation => variation.sku === SKU);
  if (!found || !found.volumeDiscounts || found.volumeDiscounts.length < 1) {
    return cloneObject(pricing);
  }

  const sorted = cloneObject(found.volumeDiscounts).sort(
    (a, b) => b.quantityBreak - a.quantityBreak
  );

  const discount = sorted.find(el => pricing.qty >= el.quantityBreak);
  if (!discount) {
    return cloneObject(pricing);
  }

  const discountPrice = discount.unitPrice || discount.unitPriceFromBulkBuy;
  if (discountPrice >= pricing.unitPrice) {
    return cloneObject(pricing);
  }

  // const bulkBuyPricing = updatePricingWithNewUnitPrice(pricing, discountPrice);
  const bulkBuyPricing = applyDiscountToPricing(pricing, discount);

  return bulkBuyPricing;
}

/**
 * Show pricing total
 * Check a CartItem Pricing Object for discount data
 * If discount has been applied, use discount line price
 * Else use line price
 * Then, check if customer is excluding GST (getters users/isCustomerExcludingGst)
 * If so, subtract GST from line price
 * @param {object} pricing - SKU price query response object
 * @param {boolean} isCustomerExcludingGst - is customer excluding GST
 * @returns {number} - total price
 */
export function calculateOrderItemLinePrice(pricing, isCustomerExcludingGst) {
  if (!pricing) return undefined;

  let linePrice = pricing.linePrice;
  let lineGst = pricing.lineGst;
  if (pricing.hasDiscount) {
    const discount = pricing.discount;
    linePrice = discount.linePrice;
    lineGst = discount.lineGst;
  }

  let price = linePrice;
  if (isCustomerExcludingGst) {
    price -= lineGst;
  }

  return round(price, 2);
}

/**
 * Calculate Base Unit Price for Checkout Cart Item
 *
 * Check a CartItem Pricing Object for discount data
 * Display base unit price, if value does not match current unit price
 * Then, check if customer is excluding GST (getters users/isCustomerExcludingGst)
 * If so, subtract GST from unit price
 * @param {object} pricing - SKU price query response object
 * @param {boolean} isCustomerExcludingGst - is customer excluding GST
 * @param {number} conversionFactor - conversion factor
 * @returns {number} - unit price
 */
export function calculateOrderItemBaseUnitPrice(
  pricing,
  isCustomerExcludingGst,
  conversionFactor
) {
  if (!pricing) return undefined;
  // if unitPrice is different to baseUnitPrice, product has been discounted
  let unitPrice = pricing.unitPrice;
  let unitGst = pricing.customerUnitGst;

  if (unitPrice !== pricing.baseUnitPrice) {
    unitPrice = pricing.baseUnitPrice;
    unitGst = pricing.baseLineGst;
  }

  if (isCustomerExcludingGst) {
    unitPrice -= unitGst;
  }

  // If conversionFactor is set and is a number greater than 1, multiply unitPrice and then round the result to 2 decimal places
  let price = unitPrice;
  if (
    conversionFactor &&
    typeof conversionFactor === 'number' &&
    conversionFactor > 0
  ) {
    let converted = unitPrice * conversionFactor + Number.EPSILON;
    converted = Math.round(converted * 100) / 100;
    price = converted;
  }

  return round(price, 2);
}

/**
 * Calculate Discounted Unit Price for Checkout Cart Item
 *
 * For Rendering our "was/now" price UI.
 * If discount has been applied, use applied discount data instead
 * Then, check if customer is excluding GST (getters users/isCustomerExcludingGst)
 * If so, subtract GST from unit price
 * @param {object} pricing - SKU price query response object
 * @param {boolean} isCustomerExcludingGst - is customer excluding GST
 * @param {number} conversionFactor - conversion factor
 * @returns {number} - unit price
 */
export function calculateOrderItemDiscountUnitPrice(
  pricing,
  isCustomerExcludingGst,
  conversionFactor
) {
  if (!pricing) return undefined;
  // if unitPrice is different to baseUnitPrice, product has been discounted
  let unitPrice = pricing.unitPrice;
  let unitGst = pricing.customerUnitGst;

  if (isCustomerExcludingGst) {
    unitPrice -= unitGst;
  }

  if (pricing.hasDiscount) {
    const discount = pricing.discount;
    unitPrice = isCustomerExcludingGst
      ? discount.unitPriceExcGst
      : discount.unitPrice;
  }

  // If conversionFactor is set and is a number greater than 1, multiply unitPrice and then round the result to 2 decimal places
  let price = unitPrice;
  if (
    conversionFactor &&
    typeof conversionFactor === 'number' &&
    conversionFactor > 0
  ) {
    let converted = unitPrice * conversionFactor + Number.EPSILON;
    converted = Math.round(converted * 100) / 100;
    price = converted;
  }

  return round(price, 2);
}

/**
 * Calculate Line GST for Checkout Cart Item
 * @param {object} pricing - SKU price query response object
 * @returns {number} - unit price
 */
export function calculateOrderItemLineGst(pricing) {
  if (!pricing) return undefined;

  let lineGst = pricing.lineGst;
  if (pricing.hasDiscount) {
    const discount = pricing.discount;
    lineGst = discount.lineGst;
  }

  return round(lineGst, 2);
}

/**
 * Calculate Unit Price for Price Query Object
 * @param {object} pricing - SKU price query response object
 * @param {number} conversionFactor - conversion factor
 * @param {boolean} isCustomerExcludingGst - is customer excluding GST
 * @returns {number} - unit price
 */
export function calculatePriceQueryUnitPrice(
  pricing,
  conversionFactor,
  isCustomerExcludingGst
) {
  if (!pricing) return undefined;

  let unitPrice = pricing.unitPrice;
  if (isCustomerExcludingGst) {
    unitPrice -= pricing.customerUnitGst;
  }

  // if conversion factor is set, is greater than zero but is not 1, multiply unitPrice and then round the result to 2 decimal places
  if (
    conversionFactor &&
    typeof conversionFactor === 'number' &&
    conversionFactor > 0 &&
    conversionFactor !== 1
  ) {
    let converted = unitPrice * conversionFactor + Number.EPSILON;
    converted = Math.round(converted * 100) / 100;
    unitPrice = converted;
  }

  return round(unitPrice, 2);
}

/**
 * Calculate Base Unit Price for Price Query Object
 * @param {object} pricing - SKU price query response object
 * @param {number} conversionFactor - conversion factor
 * @param {boolean} isCustomerExcludingGst - is customer excluding GST
 * @returns {number} - base unit price
 */
export function calculatePriceQueryBasePrice(
  pricing,
  conversionFactor,
  isCustomerExcludingGst
) {
  if (!pricing) return undefined;

  let baseUnitPrice = pricing.baseUnitPrice;
  if (isCustomerExcludingGst) {
    baseUnitPrice -= pricing.baseLineGst;
  }

  // if conversion factor is set, is greater than zero but is not 1, multiply unitPrice and then round the result to 2 decimal places
  if (
    conversionFactor &&
    typeof conversionFactor === 'number' &&
    conversionFactor > 0 &&
    conversionFactor !== 1
  ) {
    let converted = baseUnitPrice * conversionFactor + Number.EPSILON;
    converted = Math.round(converted * 100) / 100;
    baseUnitPrice = converted;
  }

  return round(baseUnitPrice, 2);
}
