//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import translate from '~/mixins/translate';
import { mapGetters } from 'vuex';

export default {
  name: 'BasePricing',
  mixins: [translate],
  props: {
    pageType: {
      type: String,
      required: false,
    },
    sku: {
      type: String,
      required: true,
    },
    basePricing: {
      type: Object,
      required: true,
    },
    priceQuery: {
      type: Array,
      required: true,
    },
    customPricing: {
      type: Object,
      required: false,
    },
    usePromotionPrices: {
      type: Boolean,
      required: false,
    },
    showUom: {
      type: Boolean,
      default: true,
    },
    currentQty: {
      type: Number,
      required: false,
      default: 1,
    },
    selectedUom: {
      type: Object,
      required: false,
    },
    isFetchingPrice: {
      type: Boolean,
      default: false,
    },
    showGstLabels: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromLabel: this.$t('product.from'),
    };
  },
  computed: {
    ...mapGetters({
      isCustomerExcludingGst: 'users/isCustomerExcludingGst',
      isUserLoggedIn: 'users/getIsLoggedIn',
    }),
    getPriceWithGst() {
      return priceObject => {
        if (!priceObject) return undefined;
        return this.isCustomerExcludingGst
          ? priceObject.excGst
          : priceObject.plusGst;
      };
    },
    hasNoGst() {
      return priceObject => {
        if (!priceObject) return undefined;
        return priceObject.excGst === priceObject.plusGst;
      };
    },
    hasBasePricing() {
      return this.isValidPriceNumber(
        this.getPriceWithGst(this.basePricing?.unitPrice)
      );
    },
    hasDefaultPricing() {
      return this.hasBasePricing || this.hasLowestPrice;
    },
    hasCustomPricing() {
      return this.isValidPriceNumber(this.customPricing?.unitPrice);
    },
    hasPromotionPricing() {
      return (
        this.usePromotionPrices &&
        this.basePricing?.promotion &&
        this.isValidPriceNumber(
          this.getPriceWithGst(this.basePricing.promotion)
        )
      );
    },
    hasLowestPrice() {
      return this.isValidPriceNumber(this.basePricing?.webProductLowest?.price);
    },
    hasHighestPrice() {
      return this.isValidPriceNumber(
        this.basePricing?.webProductHighest?.price
      );
    },
    hasRangePrice() {
      if (!this.hasLowestPrice || !this.hasHighestPrice) {
        return false;
      }

      const lowest = this.basePricing.webProductLowest.price;
      const highest = this.basePricing.webProductHighest.price;

      return lowest !== highest;
    },
    validVolumeDiscounts() {
      if (this.currentQty <= 1 || !this.bulkPricingInSelectedUom.length) {
        return [];
      }
      return this.bulkPricingInSelectedUom.filter(
        discount => this.currentQty >= discount.quantityBreak
      );
    },
    findBiggestVolumeDiscount() {
      // if customPricing "hasDiscount", this overrides volume discounts
      if (this.customPricing?.hasDiscount) {
        const sameSkuDiscount = this.customPricing?.discount;

        return {
          price: this.isCustomerExcludingGst
            ? sameSkuDiscount.unitPriceExcGst
            : sameSkuDiscount.unitPrice,
          quantityBreak: sameSkuDiscount.quantityBreak,
        };
      }

      const validDiscount = this.validVolumeDiscounts.reduce(
        (biggest, current) => {
          if (
            !biggest ||
            biggest.unitPriceFromBulkBuy >= current.unitPriceFromBulkBuy
          ) {
            return current;
          }
          return biggest;
        },
        null
      );

      if (!validDiscount) return undefined;

      return {
        price: this.isCustomerExcludingGst
          ? validDiscount.unitPriceExcGst
          : validDiscount.unitPriceFromBulkBuy,
        quantityBreak: validDiscount.quantityBreak,
      };
    },
    hasVolumeDiscount() {
      return this.findBiggestVolumeDiscount !== undefined;
    },
    basePrice() {
      if (this.hasBasePricing) {
        return this.formatPrice(
          this.getPriceWithGst(this.basePricing.unitPrice)
        );
      }
      return '';
    },
    lowestPrice() {
      if (this.hasLowestPrice) {
        return this.formatPrice(this.basePricing.webProductLowest.price);
      }
      return '';
    },
    hasDiscountedPrice() {
      return this.cheapestBasePrice > this.cheapestDiscountPrice;
    },

    hasCurrentQty() {
      return this.currentQty > 0;
    },
    skuUomMap() {
      if (!this.priceQuery.length) return {};
      return Object.fromEntries(
        this.priceQuery.map(item => [item.sku, item.uom])
      );
    },
    displayUom() {
      const sku = this.hasLowestPrice
        ? this.basePricing.webProductLowest.sku
        : this.sku;

      const uom = this.skuUomMap[sku] || '';
      return uom ? this.$uom(uom, 'productCard', 1, {}) : '';
    },
    cheapestBasePrice() {
      const unitPrice = this.getPriceWithGst(this.basePricing?.unitPrice);
      const basePrice = this.calculatePriceWithGst(
        this.customPricing?.baseUnitPrice,
        this.customPricing?.baseLineGst
      );

      const prices = [unitPrice, basePrice].filter(this.isValidPriceNumber);

      return prices.length ? Math.min(...prices) : undefined;
    },
    cheapestDiscountPrice() {
      const promotionPrice = this.getPriceWithGst(this.basePricing?.promotion);
      const unitPrice = this.calculatePriceWithGst(
        this.customPricing?.unitPrice,
        this.customPricing?.customerUnitGst
      );
      const volumePrice = this.findBiggestVolumeDiscount?.price;
      const prices = [promotionPrice, unitPrice, volumePrice].filter(
        this.isValidPriceNumber
      );

      return prices.length ? Math.min(...prices) : undefined;
    },
    bulkPricingInSelectedUom() {
      if (!this.selectedUom || !this.basePricing?.volumeDiscounts) {
        return this.basePricing?.volumeDiscounts ?? [];
      }

      const { uom, conversionFactor } = this.selectedUom;
      return this.basePricing.volumeDiscounts.map(pricing => ({
        ...pricing,
        uom,
        conversionFactor,
        quantityBreak: Math.ceil(pricing.quantityBreak / conversionFactor),
      }));
    },
  },
  methods: {
    isValidPriceNumber(value) {
      return value && typeof value === 'number' && value > 0;
    },
    formatPrice(value) {
      let price = value;
      if (this.selectedUom?.conversionFactor > 0) {
        price = value * this.selectedUom.conversionFactor;
      }
      return `$${price.toFixed(2)}`;
    },
    calculatePriceWithGst(price, lineGst = 0) {
      if (!this.isCustomerExcludingGst || !price) return price;
      return price - (lineGst || 0);
    },
  },
};
