/* eslint-disable */
export default function({ $stackdriver, $axios, $config }) {
  $axios.defaults.timeout = parseInt($config.defaultTimeout);
  $axios.interceptors.response.use(
    config => config,
    error => {
      // Check if we're in local environment
      const isLocalEnv =
        process.env.APP_ENV === 'local' || $config.appEnv === 'local';

      if (isLocalEnv) {
        // Simple console log for local development
        console.error('Axios request failed:', {
          statusCode:
            error?.statusCode || error?.response?.data?.errorCode || 'unknown',
          message:
            error?.message || error?.response?.data?.msg || 'No error message',
          url: error?.config?.url || 'No URL',
        });
      } else {
        // Production environment - use stackdriver
        try {
          if ($stackdriver) {
            const code =
              error?.statusCode || error?.response?.data?.errorCode || '';
            const msg = error?.message || error?.response?.data?.msg || '';
            const url = error?.config?.url || '';

            $stackdriver.report(`Axios Request ${code} Error: ${msg} (${url})`);
          }
        } catch (e) {
          if ($stackdriver) {
            $stackdriver.report(e);
          }
        }
      }

      return Promise.reject(error);
    }
  );
}
