//
//
//
//
//
//
//
//
//

import StarIcon from './ratingStar.vue';

export default {
  components: {
    StarIcon,
  },
  props: {
    rating: {
      type: Number,
      required: true,
    },
    reviewCount: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getStarType(index) {
      const roundedRating = Math.floor(this.rating * 2) / 2; // Round down to nearest half
      if (index <= roundedRating) {
        return 'full';
      } else if (index - 0.5 === roundedRating) {
        return 'half';
      } else {
        return 'empty';
      }
    },
  },
};
