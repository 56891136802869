import Vue from 'vue';
import Persist from '~/util/persist';
import PersistHistory from '~/util/persist-history';
import Storage from '~/util/storage';
import { version } from '../package.json';
import { round } from '../util/helper';
import {
  STORE_KEY_DIVIDER,
  STORE_MAX_HISTORY_SIZE_EACH,
} from '../util/configSettings';
import { cloneObject } from '~/util/helper';
import {
  calculateOrderItemLineGst,
  calculateOrderItemLinePrice,
} from '~/util/priceRounding';

const storage = process.client ? new Storage(window.localStorage) : undefined;
const persist = new Persist('cart', storage, version, process.client, {
  isExpirable: false,
});
const history = new PersistHistory(
  'cart',
  storage,
  STORE_MAX_HISTORY_SIZE_EACH
);

export const utils = {
  generateId() {
    const specialChars = /[^\w\s]/;
    const keys = [];

    for (const argument of [...arguments]) {
      if (argument === undefined || argument === '') {
        continue;
      }

      if (typeof argument === 'string') {
        keys.push(argument.toLowerCase());
      } else if (typeof argument === 'number') {
        keys.push(argument.toString());
      }
    }

    return keys
      .map(key => key.replace(specialChars, ''))
      .join(STORE_KEY_DIVIDER);
  },
  convertQty(qty, conversionFactor) {
    const convertedQty = qty * conversionFactor;
    return parseFloat(convertedQty.toFixed(2));
  },
  floatingPoint(v1, v2) {
    const final = v1 + v2;
    return round(final);
  },
};

export const state = () => ({
  isHydrated: false,
  items: {},
  itemsCount: 0,
});

export const mutations = {
  isHydrated(state) {
    Vue.set(state, 'isHydrated', true);
  },
  reset(state) {
    Vue.set(state, 'items', {});
    Vue.set(state, 'itemsCount', 0);

    history.clearHistory();
  },
  setItem(state, payload) {
    const key = payload.id;
    const value = persist.metatizeItem(payload.item);

    Vue.set(state.items, key, value);
  },
  addItem(state, payload) {
    const key = payload.id;
    const value = persist.metatizeItem(payload.item);

    value.data.qtyRaw = 0;
    value.data.qty = 0;
    value.data.isSubmitValid = true;

    Vue.set(state.items, key, value);

    persist.setItem('items', key, value);
    history.removeAndPush('items', key);
  },
  addQtyToItem(state, payload) {
    const key = payload.id;
    const value = state.items[key];

    value.data.qtyRaw = utils.floatingPoint(value.data.qtyRaw, payload.qtyRaw);
    value.data.qty = utils.floatingPoint(value.data.qty, payload.qty);

    Vue.set(state.items, key, value);
    persist.setItem('items', key, value);
  },
  updateValidityForItem(state, payload) {
    const key = payload.id;
    const value = state.items[key];

    if (payload.isSubmitValid !== undefined) {
      value.data.isSubmitValid = payload.isSubmitValid;
    }

    Vue.set(state.items, key, value);
    persist.setItem('items', key, value);
  },
  updateQtyForItem(state, payload) {
    const key = payload.id;
    const value = state.items[key];

    value.data.qtyRaw = parseFloat(payload.qtyRaw);
    value.data.qty = parseFloat(payload.qty);

    Vue.set(state.items, key, value);
    persist.setItem('items', key, value);
  },
  updatePricingForItem(state, payload) {
    const key = payload.id;
    const value = state.items[key];

    value.data.erp = payload.erp;
    value.data.pricing = payload.pricing;

    Vue.set(state.items, key, value);
    persist.setItem('items', key, value);
  },
  removeItem(state, payload) {
    const key = payload.id;

    Vue.delete(state.items, key);
    persist.removeItem('items', key);
    history.remove('items', key);
  },
  updateSubtotal(state, payload) {
    Vue.set(state, 'subtotal', payload.subtotal);
  },
  updateItemsCount(state) {
    if (Object.keys(state.items).length) {
      state.itemsCount = Object.keys(state.items).length;
    } else state.itemsCount = 0;
  },
  updateInventory(state, payload) {
    const itemKey = payload.itemKey;
    const value = state.items[itemKey];

    value.data.inventory = payload.inventory;
  },
};

export const getters = {
  getItems: state => () => {
    const items = {};

    for (const key in state.items) {
      if (!state.items[key]) continue;

      items[key] = state.items[key].data;
    }

    return items;
  },
  getItemFromPayload: state => payload => {
    if (!payload) throw new Error('payload is undefined');

    const {
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom,
    } = payload;

    const productId = utils.generateId(
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom
    );

    let existingItem = null;
    for (const id in state.items) {
      if (id.toLowerCase() === productId.toLowerCase()) {
        existingItem = state.items[id];
      }
    }

    if (!existingItem) {
      throw new Error('item not found');
    }

    return existingItem.data;
  },
  getItemById: state => itemId => {
    if (!itemId) return undefined;
    for (const id in state.items) {
      if (id.toLowerCase() === itemId.toLowerCase()) {
        return state.items[id];
      }
    }

    return undefined;
  },
  getItemByVariationId: state => variationId => {
    for (const id in state.items) {
      if (
        state.items[id].data.variation &&
        state.items[id].data.variation.id &&
        state.items[id].data.variation.id === variationId
      ) {
        return state.items[id].data;
      }
    }

    return undefined;
  },
  getSubtotal: state => {
    // const isCustomerExcludingGst = rootGetters['users/isCustomerExcludingGst'];

    return round(
      Object.values(state.items).reduce((total, item) => {
        const pricing = item.data.pricing;
        const linePrice = calculateOrderItemLinePrice(pricing, false);
        return linePrice ? total + linePrice : total;
      }, 0)
    );
  },
  getTotalGst: state => {
    let amount = 0;

    Object.values(state.items).forEach(item => {
      const lineItem = item.data;
      const pricing = lineItem.pricing;

      const lineGst = calculateOrderItemLineGst(pricing);
      if (lineGst) {
        amount += lineGst;
      }
    });

    return round(amount);
  },
  getTotal: state => {
    let amount = 0;

    Object.values(state.items).forEach(item => {
      const lineItem = item.data;
      const pricing = lineItem.pricing;

      const linePrice = calculateOrderItemLinePrice(pricing, false);
      if (linePrice) {
        amount += linePrice;
      }
    });

    return round(amount);
  },
  getItemsCount: state => () => {
    return state.itemsCount;
  },
  getTotalSavings: state => {
    let amount = 0;

    Object.values(state.items).forEach(item => {
      const lineItem = item.data;
      const pricing = lineItem.pricing;

      if (pricing && pricing.linePrice) {
        amount += pricing.linePrice;
      }
    });

    return round(amount);
  },
};

export const actions = {
  addStockToItem({ commit, getters }, payload) {
    const { cartId, erp } = payload;
    const existingItem = getters.getItemById(cartId);
    if (!existingItem) {
      return undefined;
    }

    const inventory = {
      isClearance: erp.isClearance,
      isRandomLength: erp.isRandomLength,
      isTally: erp.isTally,
      limitedStock: erp.limitedStock,
      specialOrder: erp.specialOrder,
      stock: erp.stock,
      timberStock: erp.lengthStock,
      stockStatus: erp.stockStatus,
      tallies: erp.tallies,
    };

    commit('updateInventory', { itemKey: cartId, inventory });
  },
  reset({ commit }) {
    commit('reset');
  },
  removeItem({ commit, getters }, payload) {
    const {
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom,
    } = payload;

    const id = utils.generateId(
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom
    );

    let existingItem = getters.getItemById(id);

    if (!existingItem) {
      throw new Error('item not found');
    }

    commit('removeItem', {
      id: id,
    });

    commit('updateItemsCount');

    return true;
  },
  async hydrate({ state, commit }) {
    if (!state.isHydrated) {
      for (const key of history.getHistory()) {
        const cached = storage.getItem(key);

        if (!cached) continue;

        const {
          productId,
          erpId,
          attribute1,
          attribute2,
          attribute3,
          attribute4,
          length,
          uom,
          qty,
          qtyRaw,
          conversionFactor,
          pricing,
          variation,
          product,
          isSubmitValid,
          inventory,
        } = cached.data;

        commit('setItem', {
          id: history.removeDataFromKey(key),
          item: {
            productId: productId,
            erpId,
            attribute1,
            attribute2,
            attribute3,
            attribute4,
            length,
            uom,
            conversionFactor,
            qty,
            qtyRaw,
            pricing,
            variation,
            product,
            isSubmitValid,
            inventory,
          },
        });
      }
    }

    commit('updateItemsCount');
    commit('isHydrated');
  },
  async getItems({ dispatch, getters }) {
    await dispatch('hydrate');

    const items = getters.getItems();

    return items;
  },
  async addItem({ getters, commit }, payload) {
    if (!payload) {
      return false;
    }
    const {
      productId,
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom,
      conversionFactor,
      qty,
      variation,
      pricing,
      product,
      inventory,
    } = payload;

    if (!erpId) {
      throw new Error('need erp id');
    }

    const id = utils.generateId(
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom
    );

    if (!id) {
      throw new Error('need generated key');
    }

    let existingItem = getters.getItemById(id);

    if (!existingItem) {
      commit('addItem', {
        id: id,
        item: {
          productId,
          erpId,
          attribute1,
          attribute2,
          attribute3,
          attribute4,
          length,
          uom,
          conversionFactor,
          variation,
          pricing,
          product,
          inventory,
        },
      });

      commit('updateItemsCount');
    }

    commit('addQtyToItem', {
      id: id,
      qtyRaw: qty,
      qty: utils.convertQty(qty, conversionFactor),
    });

    const cached = getters.getItemById(id);

    if (!cached) {
      return undefined;
    }

    return cached.data;
  },
  async updatePricingForItem({ getters, commit }, payload) {
    const {
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom,
      erp,
      pricing,
    } = payload;

    const id = utils.generateId(
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom
    );

    let existingItem = getters.getItemById(id);

    if (!existingItem) {
      throw new Error('item not found');
    }

    commit('updatePricingForItem', {
      id: id,
      erp: erp,
      pricing: pricing,
    });

    const cached = getters.getItemById(id);

    if (!cached) {
      return undefined;
    }

    return cached.data;
  },
  async updateQtyForItem({ getters, commit }, payload) {
    const {
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom,
      qty,
      conversionFactor,
    } = payload;

    const id = utils.generateId(
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom
    );

    let existingItem = getters.getItemById(id);

    if (!existingItem) {
      throw new Error('item not found');
    }

    if (qty <= 0) {
      throw new Error('incorrect qty');
    }

    commit('updateQtyForItem', {
      id: id,
      qtyRaw: qty,
      qty: utils.convertQty(qty, conversionFactor),
    });

    const cached = getters.getItemById(id);

    if (!cached) {
      return undefined;
    }

    return cached.data;
  },
  async updateValidityForItem({ getters, commit }, payload) {
    const {
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom,
      isSubmitValid,
    } = payload;

    const id = utils.generateId(
      erpId,
      attribute1,
      attribute2,
      attribute3,
      attribute4,
      length,
      uom
    );

    let existingItem = getters.getItemById(id);

    if (!existingItem) {
      throw new Error('item not found');
    }

    commit('updateValidityForItem', {
      id: id,
      isSubmitValid: isSubmitValid,
    });

    const cached = getters.getItemById(id);

    if (!cached) {
      return undefined;
    }

    return cached.data;
  },
  async refreshCartPrices({ getters, dispatch }) {
    const items = getters.getItems();

    for (const [key] of Object.entries(items)) {
      const id = items[key].erpId;
      const qty = items[key].qty;

      const pricing = await dispatch(
        'pricing/getPricingById',
        {
          id,
          qty,
        },
        { root: true }
      );

      const updatedItem = cloneObject(items[key]);
      updatedItem.pricing = cloneObject(pricing);

      await dispatch('updatePricingForItem', updatedItem);
    }

    const updatedItems = getters.getItems();
    return updatedItems;
  },
};
