var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-card__base-pricing"},[_c('div',{staticClass:"base-pricing__inner"},[(_vm.isFetchingPrice)?_c('spinner',[_c('bd-animated-loading-icon',{staticClass:"spinner",attrs:{"color":"yellow-blue","fixedSize":"large"}})],1):(_vm.hasCustomPricing || _vm.hasPromotionPricing || _vm.hasVolumeDiscount)?_c('div',{staticClass:"base-pricing__inner-custom"},[_c('div',{staticClass:"inner-line__one"},[(_vm.hasRangePrice)?_c('span',{staticClass:"hasRange mr-1"},[_vm._v(_vm._s(_vm.fromLabel))]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"customPrice base",class:{ hasDiscount: _vm.hasDiscountedPrice }},[_vm._v(_vm._s(_vm.formatPrice(_vm.cheapestBasePrice)))])]),_vm._v(" "),_c('div',{staticClass:"inner-line__two"},[(_vm.hasDiscountedPrice)?_c('span',{staticClass:"customPrice discount"},[_vm._v("\n          "+_vm._s(_vm.formatPrice(_vm.cheapestDiscountPrice))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.showUom)?_c('span',{staticClass:"customUom"},[_vm._v(_vm._s(_vm.displayUom))]):_vm._e(),_vm._v(" "),(_vm.showGstLabels)?_c('span',{staticClass:"pricing__highlighted"},[_vm._v("\n          "+_vm._s(_vm.isNoGstPrice
              ? _vm.$t('product.gst_exempt')
              : _vm.isCustomerExcludingGst
              ? _vm.$t('product.gst_excluded')
              : _vm.$t('product.gst_inclusive'))+"\n        ")]):_vm._e()])]):(_vm.hasDefaultPricing)?_c('div',{staticClass:"base-pricing__inner-default"},[(_vm.hasRangePrice)?_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.fromLabel))]):_vm._e(),_vm._v(" "),(_vm.hasLowestPrice)?_c('span',{staticClass:"defaultPrice lowest"},[_vm._v("\n        "+_vm._s(_vm.lowestPrice)+"\n      ")]):_c('span',{staticClass:"defaultPrice base"},[_vm._v(_vm._s(_vm.basePrice))]),_vm._v(" "),(_vm.showUom)?_c('span',{staticClass:"defaultUom"},[_vm._v(_vm._s(_vm.displayUom))]):_vm._e(),_vm._v(" "),(_vm.showGstLabels)?_c('span',{staticClass:"pricing__highlighted"},[_vm._v("\n        "+_vm._s(_vm.isNoGstPrice
            ? _vm.$t('product.gst_exempt')
            : _vm.isCustomerExcludingGst
            ? _vm.$t('product.gst_excluded')
            : _vm.$t('product.gst_inclusive'))+"\n      ")]):_vm._e()]):_c('div',[_c('span',{staticClass:"NaN"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }