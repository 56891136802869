import Vue from 'vue';
import Persist from '~/util/persist';
import PersistHistory from '~/util/persist-history';
import Storage from '~/util/storage';
import {
  STORE_KEY_DIVIDER,
  STORE_MAX_HISTORY_SIZE_EACH,
  STORE_VERSION,
} from '../util/configSettings';

const storage = process.client ? new Storage(window.localStorage) : undefined;
const persist = new Persist(
  'pricing',
  storage,
  STORE_VERSION,
  process.client,
  {}
);
const history = new PersistHistory(
  'pricing',
  storage,
  STORE_MAX_HISTORY_SIZE_EACH
);

export const utils = {
  generateId(id, qty) {
    return id + STORE_KEY_DIVIDER + qty;
  },
  mapPriceData(pricing) {
    if (!pricing || pricing.error || !pricing.unitPrice) {
      return {
        error: true,
        msg: pricing?.msg ?? 'error fetching price',
        id: pricing?.sku ?? '',
        qty: pricing?.qty ?? 0,
      };
    }

    return {
      error: false,
      baseUnitPrice: pricing.baseUnitPrice,
      baseLinePrice: pricing.baseLinePrice,
      baseLineGst: pricing.baseLineGst,
      customerUnitGst: pricing.customerUnitGst,
      defaultUnitPrice: pricing.defaultUnitPrice,
      incGst: pricing.incGst,
      lineGst: pricing.lineGst,
      id: pricing.sku,
      qty: pricing.qty,
      linePrice: pricing.linePrice,
      unitPrice: pricing.unitPrice,
      hasBulkBuy: pricing.hasBulkBuy,
    };
  },
};

export const state = () => ({
  isHydrated: false,
  pricingCount: 0,
  pricingItems: [],
  promises: {},
  timeout: null,
  pricings: {},
});

export const mutations = {
  isHydrated(state) {
    Vue.set(state, 'isHydrated', true);
  },
  addPrice(state, data) {
    const key = utils.generateId(data.id, data.qty);
    const value = persist.metatizePriceItem(data);

    Vue.set(state.pricings, key, value);

    if (state.promises[key]) {
      for (const resolve of state.promises[key]) {
        resolve();
      }
      delete state.promises[key];
    }

    persist.setItem('pricings', key, value);
    history.removeAndPush('pricings', key);
  },
  addPriceFromLocalStorage(state, value) {
    const key = utils.generateId(value.data.id, value.data.qty);

    if (!state.pricings[key]) {
      Vue.set(state.pricings, key, value);
    }
  },
  clearQueue(state) {
    state.pricingCount = 0;
    state.pricingItems = [];
    state.timeout = null;
  },
  addToQueue(state, data) {
    state.pricingCount++;
    state.pricingItems.push(data);
  },
  async addPromise(state, { resolve, id, qty }) {
    const uniqueId = utils.generateId(id, qty);
    if (!state.promises[uniqueId]) {
      state.promises[uniqueId] = [];
    }

    state.promises[uniqueId].push(resolve);

    return uniqueId;
  },
  clearPromises(state) {
    Object.keys(state.promises).forEach(key => {
      for (const resolve of state.promises[key]) {
        resolve();
      }
    });
    state.promises = {};
  },
  updateTimeout(state, timeout) {
    clearTimeout(state.timeout);
    state.timeout = timeout;
  },
  clearPricingStore(state) {
    state.pricingCount = 0;
    state.pricingItems = [];
    state.promises = {};
    state.timeout = null;
    state.pricings = {};
  },
};

export const getters = {
  someGetter: rootGetters => {
    const result = {
      getContact: rootGetters['users/getContact'],
      getIsLoggedIn: rootGetters['users/getIsLoggedIn'],
      getCustomer: rootGetters['users/getCustomer'],
      getHydrationStatus: rootGetters['users/getHydrationStatus'],
    };

    return result;
  },
  getPricingItemByIdAndQty: state => ({ id, qty }) => {
    const key = utils.generateId(id, qty);
    let item = state.pricings[key];

    if (!item) {
      return undefined;
    }

    return item;
  },
};

export const actions = {
  async getPricingById({ getters, dispatch }, { id, qty = 1 }) {
    let item = getters.getPricingItemByIdAndQty({
      id: id,
      qty: qty,
    });

    // console.log('getPricingById - cached item: ', id, qty, item);
    if (persist.isValidExpiration(item)) {
      // console.log('getPricingById - has valid cache', id, qty);
      return item.data;
    }

    // console.log('getPricingById - refetching', id, qty);
    const pricing = await dispatch('fetchPrice', {
      id: id,
      qty: qty,
    });

    return pricing;
  },
  async fetchPrice({ state, getters, commit, dispatch }, params) {
    commit('addToQueue', {
      sku: params.id,
      qty: params.qty,
    });

    let resolve;
    const promise = new Promise(_resolve => {
      resolve = _resolve;
    });

    commit('addPromise', {
      resolve,
      id: params.id,
      qty: params.qty,
    });

    if (state.pricingCount >= 50) {
      dispatch('runPriceRequest');
    }

    commit(
      'updateTimeout',
      setTimeout(() => {
        dispatch('runPriceRequest');
      }, 50)
    );

    try {
      await promise;
    } catch (e) {
      console.error(e);
      return undefined;
    }

    let item = getters.getPricingItemByIdAndQty({
      id: params.id,
      qty: params.qty,
    });

    if (!item) {
      return undefined;
    }

    const pricing = item.data;

    if (pricing.error) {
      return undefined;
    }

    return pricing;
  },

  async runPriceRequest({ state, commit, rootGetters }) {
    clearTimeout(state.timeout);

    if (state.pricingItems.length < 1) {
      return;
    }

    const payload = [...state.pricingItems];
    commit('clearQueue');

    let result = undefined;

    // check payload array of objects, only 1 item can have the same combination of sku and qty (same sku and different qty is ok)
    const uniquePayload = [];
    const uniquePayloadMap = {};
    for (let i = 0; i < payload.length; i++) {
      const key = utils.generateId(payload[i].sku, payload[i].qty);
      if (!uniquePayloadMap[key]) {
        uniquePayloadMap[key] = true;
        uniquePayload.push(payload[i]);
      }
    }

    // console.log('runPriceRequest', uniquePayload);
    if (uniquePayload.length < 1) {
      // console.log(
      //   'uniquePayload is empty',
      //   uniquePayload,
      //   'originalList',
      //   payload
      // );
      return;
    }

    // check user admin permissions
    // if "showCustomerPrice" is defined, and equals FALSE, do not fetch custom pricing
    let isDisabled = false;

    const userContact = rootGetters['users/getContact'];
    if (
      userContact &&
      userContact.permissions &&
      userContact.permissions.rules
    ) {
      if (
        typeof userContact.permissions.rules.showCustomerPrice === 'boolean'
      ) {
        if (userContact.permissions.rules.showCustomerPrice === false) {
          isDisabled = true;
        }
      }
    }

    const path = isDisabled
      ? '/products-pricing/guest-prices'
      : '/products-pricing/prices';

    try {
      // get current axios config, like current hostnamt
      // console.log('fetchPrice - host', this.$axios.defaults.baseURL);
      // console.log('fetchPrice - path', path);
      result = await this.$axios.post(path, uniquePayload);
      let response = result.data;
      if (response.error) {
        throw response;
      }

      for (let i = 0; i < response.data.length; i++) {
        const item = utils.mapPriceData(response.data[i]);
        // BOW-206 - done cache failed price queries, let them rerun next time
        if (typeof item.error === 'boolean' && !item.error) {
          commit('addPrice', item);
        }
      }
    } catch (e) {
      const skus = [];
      payload.forEach(item => {
        skus.push(item.sku);
      });

      if (result) {
        console.error('pricing api: Failed Price result: ', result);
      } else {
        console.log('failed fetch host', this.$axios.defaults.baseURL);
        console.log('failed fetch path', path);
        console.error(
          'pricing api: error fetching pricing details',
          e,
          uniquePayload
        );
      }

      commit('clearPromises');
    }
  },
  async bustPriceCache({ commit, dispatch }) {
    commit('clearPricingStore');

    const localStoragePricingHistory = history.getHistory();
    localStoragePricingHistory.forEach(storageKey => {
      const key = history.removeDataFromKey(storageKey);
      persist.removeItem('pricings', key);
    });

    history.clearHistory();
    await dispatch('cart/refreshCartPrices', null, { root: true });
  },
  async hydrate({ state, commit }) {
    if (!state.isHydrated) {
      for (const key of history.getHistory()) {
        const cached = storage.getItem(key);
        if (!cached) continue;

        commit('addPriceFromLocalStorage', cached);
      }
      commit('isHydrated');
    }
  },
};
