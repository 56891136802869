//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import gtmMixins from '~/mixins/gtm';
import NonGFormMixins from '~/mixins/nonGForms';
import CloseIcon from '~/static/svg/cross-blue.svg';
import LogoutIcon from '~/static/svg/logout-blue.svg';

export default {
  mixins: [gtmMixins, NonGFormMixins],
  components: {
    CloseIcon,
    LogoutIcon,
  },
  data() {
    return {
      windowWidth: '',
      redirectTo: '/',
      switchMessage: '',
      switchMessageType: '',
    };
  },
  props: {
    loggedIn: {
      type: Boolean,
      required: true,
    },
    isChinbar: {
      type: Boolean,
      required: false,
      default: false,
    },
    popUpPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    pdpPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      logoutUser: 'users/logoutUser',
      switchCompany: 'users/switchCompany',
      bustPriceCache: 'pricing/bustPriceCache',
      clearOrderContactDetails: 'checkout/clearOrderContactDetails',
      getUserJobLists: 'joblist/getUserJobLists',
      resetCart: 'cart/reset',
      resetCheckout: 'checkout/reset',
    }),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async handleLogout() {
      await this.logoutUser();
      this.$router.push({
        path: '/',
      });
      this.$emit('closePopup');
    },
    handleClose() {
      this.$emit('close');
    },
    closePopup() {
      this.$emit('closePopup');
    },
    handleMyAccountClick() {
      this.closePopup();
    },
    async handleCompanySwitch(selectedCompany) {
      console.log('Switching company to:', selectedCompany);
      const payload = { customerId: selectedCompany.value };

      const resp = await this.switchCompany(payload);
      console.log('Switch company response:', resp);

      if (!resp || resp.error) {
        this.switchMessage = this.$t(
          'general.logged_in_popup.company_switch_failed'
        );
        this.switchMessageType = 'error';
        return;
      }

      await this.bustPriceCache();
      await this.clearOrderContactDetails();
      await this.getUserJobLists();

      this.switchMessage = this.$t(
        'general.logged_in_popup.company_switch_success'
      );
      this.switchMessageType = 'success';

      setTimeout(() => {
        this.switchMessage = '';
        this.$emit('closePopup');
        this.reloadPage();
      }, 500);
    },
    reloadPage(delay = 600) {
      setTimeout(() => {
        window.location.reload();
      }, delay);
    },
  },
  computed: {
    ...mapGetters({
      getCustomer: 'users/getCustomer',
      getContact: 'users/getContact',
      getLinkedCompanies: 'users/getLinkedCompanies',
    }),
    linkedCompanies() {
      return (this.getLinkedCompanies || []).map(c => ({
        value: c.customerId,
        text: c.name,
        acctType: c.acctType,
      }));
    },
    currentCompany() {
      return {
        value: this.getCustomer?.customerId ?? null,
        text: this.getCustomer?.name ?? '',
        acctType: this.getCustomer?.typeAcctAr ?? '',
      };
    },
    canUseJobListFeatures() {
      return this.$config.featureToggle.toggles.enableJobListFeature;
    },
    canUseMagicLinkLogin() {
      return this.$config.featureToggle.toggles.enableMagicLinkLogin;
    },
    offsetStyle() {
      if (this.popUpPage) {
        return `right: 0px; height: 100vh;`;
      }
      const maxWidth = 2560;
      const screenWidth = this.windowWidth;
      if (screenWidth > maxWidth) {
        const sideSpace = (screenWidth - maxWidth) / 2;
        const adjustedRightPosition = sideSpace - 25;
        return `right: ${Math.max(adjustedRightPosition, 0)}px; height: 100vh;`;
      }
      return `right: 0; height: 100vh;`;
    },
    containerStyle() {
      if (this.isChinbar) {
        return `login-account-popup__chinbar ${
          this.loggedIn ? 'login-account-popup__chinbar-lgo' : ''
        }`;
      } else if (this.popUpPage) {
        return `login-account-popup__header-pop-up`;
      } else {
        return `login-account-popup__header`;
      }
    },
    closeBtnStyle() {
      if (this.loggedIn) {
        return 'login-account-popup__close login-account-popup__close-lg';
      } else {
        return 'login-account-popup__close login-account-popup__close-lgo';
      }
    },
    computedTitle() {
      return this.popUpPage
        ? this.$t('general.header.job_list_title')
        : this.$t('general.header.my_account');
    },
  },
  mounted() {
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  beforeMount() {
    window.addEventListener('resize', this.handleResize);
  },
};
