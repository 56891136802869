import recommend from '@algolia/recommend';

const VALID_INDEX_TYPES = ['related', 'trending', 'together'];

/**
 * Conver Algolia Result to Bowens Product
 * Our product detail cards used in the standard catalogue/carousel
 * have a different object structure to that of our algolia result items.
 * This method take a result and maps it into the standard product object used
 * but all our frontend components.
 *
 * @param {object} resultItem - Algolia Recommend Result Object
 * @returns {object} - Standard Bowens Product Details Object
 */
export function convertResultToProduct(resultItem = null) {
  if (!resultItem) return;

  //Fill Related Products with Recommendations up to List Limit
  let productUrl = '/p/' + resultItem.slug;
  if (resultItem.queryId) productUrl += `?q=${resultItem.queryId}`;

  // Convert Algolia result into Standard Product Details Object
  return {
    title: resultItem.title,
    url: productUrl,
    imageUrl: resultItem.imageUrl,
    imageUrlWebp: resultItem.imageUrlWebp,
    imageTwoUrl: resultItem.imageTwoUrl,
    imageTwoUrlWebp: resultItem.imageTwoUrlWebp,
    variationImageUrl: resultItem.variationImageUrl,
    variationImageUrlWebp: resultItem.variationImageUrlWebp,
    variationImageTwoUrl: resultItem.variationImageTwoUrl,
    variationImageTwoUrlWebp: resultItem.variationImageTwoUrlWebp,
    id: resultItem.parentID,
    categories: resultItem.categories,
    priceQuery: resultItem.priceQuery,
    limitedStock: resultItem.limitedStock,
    specialOrder: resultItem.specialOrder,
    objectID: resultItem.objectID,
    parentID: resultItem.parentID,
    sku: resultItem.sku,
    slug: resultItem.slug,
    basePricing: resultItem.basePricing,
    isTimberTally: resultItem.flags?.timberTally,
    deliveryOptions: {
      uber: resultItem.deliveryOptions?.uber,
    },
    reviews: resultItem.reviews,
    stock: resultItem.stock,
  };
}

/**
 * Filter Recommendation Results
 * Have not yet fully optimised the algolia recommend algorithms
 * because of this, we tend to have issues such as:
 *
 * 1. duplicate results
 * 2. conflicts with parent/variant products
 * 3. already exist in the manually sorted related products list
 *
 * Filter down the algolia results based on these requirements.
 * @param {array} recommendations - results from algolia recommend api
 * @param {array} relatedProducts - manually chosen related products from the business
 * @param {array} variationIds - list of skus specific to this web product and its variations
 * @returns {array} - filtered list of recommendations
 */
export async function filterRecommendations(
  recommendations,
  relatedProducts,
  variationIds
) {
  let filteredResults = [];
  let variantObjectIds = variationIds || [];
  let related = relatedProducts || [];

  recommendations.forEach(item => {
    let filter = true;

    //1. Is this a variant of current product?
    variantObjectIds.forEach(variantId => {
      let idCheck = [item.sku, item.objectID, item.parentID];
      if (idCheck.indexOf(variantId) >= 0) filter = false;
    });

    //2. Does this match with an existing related product
    related.forEach(product => {
      if (item.parentID === product.id) filter = false;
      let productId = product.id.replace('Parent_', '').trim();
      if (item.sku === productId) filter = false;
      if (item.slug === product.slug) filter = false;
    });

    //3. Is There already a variant of this web product in the filtered list?
    filteredResults.forEach(result => {
      if (item.sku === result.sku) filter = false;
      if (item.objectID === result.objectID) filter = false;
      if (item.parentID === result.parentID) filter = false;
      if (item.slug === result.slug) filter = false;
    });

    if (filter) filteredResults.push(Object.assign({}, item));
  });

  return filteredResults;
}

/**
 * Refine Algolia Result
 * Results returned from the algolia api arent immediately usable by
 * our frontend components.
 *
 * Converting them into bowens product detail objects
 * @param {array} resultItems - An Array of Algolia Search Result Objects
 * @returns {array} - Array of Standard Bowens Product Details Objects
 */
export async function refineAlgoliaResults(resultItems = []) {
  if (resultItems?.length <= 0) return;

  // 1. Refine Algolia Results into Bowens Products
  const refinedResults = [];
  for (let ix = 0; ix < resultItems.length; ix++) {
    refinedResults.push(convertResultToProduct(resultItems[ix]));
  }

  return refinedResults;
}

/**
 * IsValidType
 * There are three types of algolia recommend model available.
 * Each has their own API method and config params to get
 * results from the algolia api.
 *
 * Verify we have decalred a valid type.
 *
 * @param {string} indexType - type variable to check
 * @returns {boolean} - if submitted variable matches an allowed type, return true
 */
export function isValidType(indexType = null) {
  if (!indexType) return false;
  return VALID_INDEX_TYPES.indexOf(indexType) >= 0;
}

export class AlgoliaRecommend {
  indexType;
  indexName;
  client;
  appId;
  appKey;

  constructor(params) {
    this.indexType = params.indexType || null;
    this.indexName = params.indexName;
    this.appId = params.appId;
    this.appKey = params.appKey;
    this.client = null;
    this.resultLimit = 15;
    this.threshold = 70;

    this.client = this.initialiseApiClient();

    return this.isApiInitialised();
  }

  initialiseApiClient() {
    return recommend(this.appId, this.appKey);
  }

  isApiInitialised() {
    if (!this.indexName) return false;
    if (!this.indexType) return false;
    if (!this.client) return false;

    return true;
  }

  setResultLimit(newLimit = 10) {
    if (typeof newLimit === 'number' && newLimit >= 0) {
      this.resultLimit = newLimit;
    }
  }

  getApiMethod() {
    switch (this.indexType) {
      case 'related':
        return 'getRelatedProducts';
      case 'trending':
        return 'getTrendingItems';
      case 'together':
        return 'getFrequentlyBoughtTogether';
    }

    return null;
  }

  generateApiRequest(params) {
    switch (this.indexType) {
      case 'related':
        return this.generateRelatedRequest(params);
      case 'trending':
        return this.generateTrendingRequest(params);
      case 'together':
        return this.generateTogetherRequest(params);
    }

    return null;
  }

  generateRelatedRequest(params) {
    const request = {
      indexName: this.indexName,
      objectID: params.objectID,
      maxRecommendations: params.listLimit || this.resultLimit,
      queryParameters: this.generateQueryParameters(params.queryParameters),
    };

    return [request];
  }

  generateTrendingRequest(params) {
    const request = {
      indexName: this.indexName,
      threshold: params.threshold || this.threshold,
      maxRecommendations: params.listLimit || this.resultLimit,
      queryParameters: this.generateQueryParameters(params.queryParameters),
    };

    return [request];
  }

  generateTogetherRequest(params) {
    const request = [];

    if (params.objectIDs) {
      params.objectIDs.forEach(objectID => {
        request.push({
          indexName: this.indexName,
          objectID,
          maxRecommendations: params.listLimit || this.resultLimit,
          queryParameters: this.generateQueryParameters(params.queryParameters),
        });
      });
    }

    return request;
  }

  generateQueryParameters(newParams = null) {
    const queryParameters = {
      distinct: true,
      filters: "status:'public'",
      clickAnalytics: true,
      enableRules: true,
    };

    if (newParams) {
      Object.assign(queryParameters, newParams);
    }

    return queryParameters;
  }

  hasValidResults(response = null) {
    if (response?.results?.length <= 0) return false;
    let hasHit = false;
    response.results.forEach(result => {
      if (result?.hits?.length > 0) hasHit = true;
    });

    if (hasHit) return true;
    return false;
  }

  // Multiple Requests can be sent at once, for now only do one.
  isValidRequest(request = []) {
    if (!request || request.length <= 0) return false;
    switch (this.indexType) {
      case 'related':
        return this.validateRelatedRequest(request[0]);
      case 'trending':
        return this.validateTrendingRequest(request[0]);
      case 'together':
        return this.validateTogetherRequest(request[0]);
    }

    return false;
  }

  validateRelatedRequest(request = null) {
    if (!request) return false;
    return request.indexName && request.objectID;
  }

  validateTrendingRequest(request = null) {
    if (!request) return false;
    return request.indexName;
  }

  validateTogetherRequest(request = null) {
    if (!request) return false;
    return request.indexName && request.objectID;
  }

  async getRecommendations(params) {
    let request;
    try {
      if (!this.isApiInitialised()) {
        console.log('ALGOLIA API: Failed to Initialise');
        return [];
      }

      // 1. Determine Api Method based on type
      const apiMethod = this.getApiMethod();
      // 2. Generate Request based on type
      request = this.generateApiRequest(params);
      if (!this.isValidRequest(request)) {
        console.log('ALGOLIA API: Invalid Request', request);
        return [];
      }

      // 3. Get Recommendations
      const response = await this.client[apiMethod](request);
      if (!this.hasValidResults(response)) {
        console.log('ALGOLIA API: Invalid Results', response);
        return [];
      }

      let hits = [];
      if (response.results.length > 0) {
        // Use flatMap to extract and merge the "hits" arrays
        hits = response.results.flatMap(obj => obj.hits);
      }

      return await refineAlgoliaResults(hits);
    } catch (e) {
      console.log('AlgoliaApi.getRecommendations CATCH');
      console.log('params: ', params);
      console.log(e);

      return [];
    }
  }
}
