export default {
  methods: {
    customMessages(label, maxLength = 0, minLength = 0) {
      return {
        required: this.$t('form.validations.required', { name: label }),
        email: this.$t('form.validations.email'),
        max: this.$t('form.validations.max', { count: maxLength }),
        min: this.$t('form.validations.min', { count: minLength }),
        mimes: this.$t('form.validations.mimes'),
        size: this.$t('form.validations.size'),
      };
    },
  }
};