import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_6c71d38a from 'nuxt_plugin_sentryserver_6c71d38a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_06b86202 from 'nuxt_plugin_sentryclient_06b86202' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_332886b0 from 'nuxt_plugin_plugin_332886b0' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_featuretoggles_cfef1a18 from 'nuxt_plugin_featuretoggles_cfef1a18' // Source: ./feature-toggles.js (mode: 'all')
import nuxt_plugin_pluginrouting_7a904d40 from 'nuxt_plugin_pluginrouting_7a904d40' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_09dda62f from 'nuxt_plugin_pluginmain_09dda62f' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_dayjsplugin_08e83457 from 'nuxt_plugin_dayjsplugin_08e83457' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_gtm_1e4bba1c from 'nuxt_plugin_gtm_1e4bba1c' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_recaptcha_116d1596 from 'nuxt_plugin_recaptcha_116d1596' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_axios_1b5b39c6 from 'nuxt_plugin_axios_1b5b39c6' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_vueconfig_4265ef90 from 'nuxt_plugin_vueconfig_4265ef90' // Source: ../plugins/vue-config.js (mode: 'client')
import nuxt_plugin_HackTimer_32cd107c from 'nuxt_plugin_HackTimer_32cd107c' // Source: ../node_modules/hacktimer/HackTimer.js (mode: 'client')
import nuxt_plugin_errorlogging_76ff3293 from 'nuxt_plugin_errorlogging_76ff3293' // Source: ../plugins/error-logging.js (mode: 'client')
import nuxt_plugin_vuexclientinit_2f92d72a from 'nuxt_plugin_vuexclientinit_2f92d72a' // Source: ../plugins/vuex-client-init.js (mode: 'client')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_vuecookies_6b36a0a5 from 'nuxt_plugin_vuecookies_6b36a0a5' // Source: ../plugins/vue-cookies.js (mode: 'client')
import nuxt_plugin_vueslickcarousel_1c6345a5 from 'nuxt_plugin_vueslickcarousel_1c6345a5' // Source: ../plugins/vue-slick-carousel.js (mode: 'all')
import nuxt_plugin_vuebarcode_146d2dd8 from 'nuxt_plugin_vuebarcode_146d2dd8' // Source: ../plugins/vue-barcode.js (mode: 'all')
import nuxt_plugin_algoliainstantsearch_b9c4ba00 from 'nuxt_plugin_algoliainstantsearch_b9c4ba00' // Source: ../plugins/algolia-instantsearch.js (mode: 'client')
import nuxt_plugin_vuels_730c7553 from 'nuxt_plugin_vuels_730c7553' // Source: ../plugins/vue-ls.js (mode: 'client')
import nuxt_plugin_youtube_eb95502c from 'nuxt_plugin_youtube_eb95502c' // Source: ../plugins/youtube.js (mode: 'client')
import nuxt_plugin_veevalidate_1a0c1998 from 'nuxt_plugin_veevalidate_1a0c1998' // Source: ../plugins/vee-validate.js (mode: 'all')
import nuxt_plugin_directives_521c0486 from 'nuxt_plugin_directives_521c0486' // Source: ../plugins/directives.js (mode: 'all')
import nuxt_plugin_vuexsharedmutations_37627e10 from 'nuxt_plugin_vuexsharedmutations_37627e10' // Source: ../plugins/vuex-shared-mutations.js (mode: 'client')
import nuxt_plugin_hydrate_a9c5178c from 'nuxt_plugin_hydrate_a9c5178c' // Source: ../plugins/hydrate.js (mode: 'client')
import nuxt_plugin_espotbanners_8e77c028 from 'nuxt_plugin_espotbanners_8e77c028' // Source: ../plugins/espot-banners.js (mode: 'client')
import nuxt_plugin_maintenancemode_3d13e184 from 'nuxt_plugin_maintenancemode_3d13e184' // Source: ../plugins/maintenance-mode.js (mode: 'client')
import nuxt_plugin_dayjs_0a8597e7 from 'nuxt_plugin_dayjs_0a8597e7' // Source: ../plugins/day-js.js (mode: 'all')
import nuxt_plugin_usertokens_257ab791 from 'nuxt_plugin_usertokens_257ab791' // Source: ../plugins/user-tokens.js (mode: 'client')
import nuxt_plugin_serviceworker_14d2d7eb from 'nuxt_plugin_serviceworker_14d2d7eb' // Source: ../plugins/service-worker.js (mode: 'client')
import nuxt_plugin_vuegoodtable_e6373c88 from 'nuxt_plugin_vuegoodtable_e6373c88' // Source: ../plugins/vue-good-table.js (mode: 'all')
import nuxt_plugin_cypress_69effd80 from 'nuxt_plugin_cypress_69effd80' // Source: ../plugins/cypress.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    return this.$root.$options.$nuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule
const baseStoreOptions = { preserveState: process.client }

function registerModule (path, rawModule, options = {}) {
  return originalRegisterModule.call(this, path, rawModule, { ...baseStoreOptions, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Bowens","description":"Visit Bowens the timber & hardware specialists. Same-day delivery, click + collect, Uber delivery. Shop 50,000+ building products online & in-store.","meta":[{"charset":"utf-8"},{"hid":"Content-Type","http-equiv":"Content-Type","content":"text\u002Fhtml; charset=UTF-8"},{"hid":"X-UA-Compatible","http-equiv":"X-UA-Compatible","content":"IE=edge"},{"hid":"format-detection","name":"format-detection","content":"telephone=no"},{"hid":"og_locale","property":"og:locale","content":"en_AU"},{"hid":"og_type","property":"og:type","content":"website"},{"hid":"og_url","property":"og:url","content":undefined},{"name":"viewport","content":"width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"},{"hid":"robots","name":"robots","content":"max-snippet:-1, max-image-preview:large, max-video-preview:-1"}],"link":[{"rel":"icon","type":"image\u002Fpng","href":"\u002Ffavicon.png"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function (w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_6c71d38a === 'function') {
    await nuxt_plugin_sentryserver_6c71d38a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_06b86202 === 'function') {
    await nuxt_plugin_sentryclient_06b86202(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_332886b0 === 'function') {
    await nuxt_plugin_plugin_332886b0(app.context, inject)
  }

  if (typeof nuxt_plugin_featuretoggles_cfef1a18 === 'function') {
    await nuxt_plugin_featuretoggles_cfef1a18(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_7a904d40 === 'function') {
    await nuxt_plugin_pluginrouting_7a904d40(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_09dda62f === 'function') {
    await nuxt_plugin_pluginmain_09dda62f(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_08e83457 === 'function') {
    await nuxt_plugin_dayjsplugin_08e83457(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_1e4bba1c === 'function') {
    await nuxt_plugin_gtm_1e4bba1c(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_116d1596 === 'function') {
    await nuxt_plugin_recaptcha_116d1596(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_1b5b39c6 === 'function') {
    await nuxt_plugin_axios_1b5b39c6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueconfig_4265ef90 === 'function') {
    await nuxt_plugin_vueconfig_4265ef90(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_HackTimer_32cd107c === 'function') {
    await nuxt_plugin_HackTimer_32cd107c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_errorlogging_76ff3293 === 'function') {
    await nuxt_plugin_errorlogging_76ff3293(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexclientinit_2f92d72a === 'function') {
    await nuxt_plugin_vuexclientinit_2f92d72a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecookies_6b36a0a5 === 'function') {
    await nuxt_plugin_vuecookies_6b36a0a5(app.context, inject)
  }

  if (typeof nuxt_plugin_vueslickcarousel_1c6345a5 === 'function') {
    await nuxt_plugin_vueslickcarousel_1c6345a5(app.context, inject)
  }

  if (typeof nuxt_plugin_vuebarcode_146d2dd8 === 'function') {
    await nuxt_plugin_vuebarcode_146d2dd8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_algoliainstantsearch_b9c4ba00 === 'function') {
    await nuxt_plugin_algoliainstantsearch_b9c4ba00(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuels_730c7553 === 'function') {
    await nuxt_plugin_vuels_730c7553(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_youtube_eb95502c === 'function') {
    await nuxt_plugin_youtube_eb95502c(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_1a0c1998 === 'function') {
    await nuxt_plugin_veevalidate_1a0c1998(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_521c0486 === 'function') {
    await nuxt_plugin_directives_521c0486(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexsharedmutations_37627e10 === 'function') {
    await nuxt_plugin_vuexsharedmutations_37627e10(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hydrate_a9c5178c === 'function') {
    await nuxt_plugin_hydrate_a9c5178c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_espotbanners_8e77c028 === 'function') {
    await nuxt_plugin_espotbanners_8e77c028(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_maintenancemode_3d13e184 === 'function') {
    await nuxt_plugin_maintenancemode_3d13e184(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_0a8597e7 === 'function') {
    await nuxt_plugin_dayjs_0a8597e7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_usertokens_257ab791 === 'function') {
    await nuxt_plugin_usertokens_257ab791(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_serviceworker_14d2d7eb === 'function') {
    await nuxt_plugin_serviceworker_14d2d7eb(app.context, inject)
  }

  if (typeof nuxt_plugin_vuegoodtable_e6373c88 === 'function') {
    await nuxt_plugin_vuegoodtable_e6373c88(app.context, inject)
  }

  if (typeof nuxt_plugin_cypress_69effd80 === 'function') {
    await nuxt_plugin_cypress_69effd80(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
