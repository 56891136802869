/* eslint-disable require-jsdoc */
import { round } from './helper';
import { LINEAL_METER, LINEAL_METER_CONVERSION_FACTOR, STICK } from './product';

export const DELIVERY_TYPE = {
  CLICK_COLLECT: 'bowens_pickup',
  DELIVERY: 'bowens_delivery',
  NATIONAL_DELIVERY: 'bowens_national_delivery',
  UBER_DELIVERY: 'bowens_uber_delivery',
  INTERNATIONAL_DELIVERY: 'bowens_international_delivery',
};

export const TODAY_DELIVERY_CUTOFF = {
  cutoffHour: 10,
  cutoffMinute: 5,
};

export const AVAILABILITY = {
  NOW: 0,
  ASAP: 1,
  TWO_DAYS: 2,
  ONE_WEEK: 7,
  TWO_WEEKS: 14,
  DEFAULT: 14,
};

export const CUSTOMER_DELIVERY_TYPES = {
  UBER: 'uber',
  PARCEL: 'parcel',
  UTE: 'ute',
  TRUCK: 'truck',
  CRANE_TRUCK: 'crane_truck',
  INTL_PARCEL: 'intl_parcel',
};

export const CUSTOMER_DELIVERY_STATUS = {
  DISABLED: 'disabled', // Cannot be selected
  ACTIVE: 'active', // Can be selected
  SELECTED: 'selected', // Currently selected
};

export const ACCOUNT_STATUS = {
  ON_HOLD: 'h',
  SUSPENDED: 's',
};

/**
 *
 * @param {integer} qty item qty
 * @param {string} length length in meters
 *
 * @returns {integer} stick conversion of qty
 */
export function convertQtyToStick(qty, length) {
  return Math.ceil(qty / parseFloat(length));
}

export function getTotalLengthStock(lengthStocks, length, closestStore = null) {
  if (!lengthStocks) {
    return 0;
  }

  let total = 0;
  for (const branchId in lengthStocks) {
    if (closestStore && String(closestStore) !== branchId) {
      continue;
    }

    if (lengthStocks[branchId][length]) {
      total += lengthStocks[branchId][length].stock;
    }
  }

  return total;
}

export function getTotalStock(stocks, closestStore) {
  if (!stocks) {
    return 0;
  }

  let total = 0;
  for (const branchId of Object.keys(stocks)) {
    if (closestStore && String(closestStore) !== branchId) continue;

    const stock = stocks[branchId];
    total += stock.availableStock;
  }

  return total;
}

/**
 * Bowens Same-Day Delivery Cutoff Time is 10am,
 * Check current time to determine if same-day is still available.
 *
 * Add 5 minutes to the Hardcoded cutoff time, to allow for a buffer
 * after the cutoff for any in progress orders
 *
 * @returns {boolean} true if before cutoff, false if after cutoff
 */
export function isBeforeTodayDeliveryCutoff() {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const { cutoffHour, cutoffMinute } = TODAY_DELIVERY_CUTOFF;

  if (
    currentHour < cutoffHour ||
    (currentHour === cutoffHour && currentMinute < cutoffMinute)
  ) {
    return true;
  }

  return false;
}

function getTimberLengthAvailability(
  qty,
  length,
  lengthStock,
  closestStore = null,
  canDeliverToday,
  isSameDayEnabled = false
) {
  // Submitted QTY is based on Variant Length Amount
  // Convert QTY to 1m amounts
  const updatedQty = convertQtyToStick(qty, length);

  // Get Total Stock, or Single Store Stock if Closest Store is available
  const closestLengthStock = getTotalLengthStock(
    lengthStock,
    length,
    closestStore
  );

  if (isSameDayEnabled && closestStore) {
    if (closestLengthStock > updatedQty) {
      if (canDeliverToday) {
        return AVAILABILITY.NOW;
      }

      return AVAILABILITY.ASAP;
    }
  }

  // Get "All Stores" Stock
  const totalLengthStock = getTotalLengthStock(lengthStock, length);
  if (totalLengthStock && totalLengthStock > updatedQty) {
    if (isSameDayEnabled) return AVAILABILITY.ONE_WEEK;
    else return AVAILABILITY.NOW;
  }

  return AVAILABILITY.TWO_WEEKS;
}

function getStockAvailability(
  qty,
  stock,
  closestStore,
  canDeliverToday,
  isSameDayEnabled = false
) {
  // closest store will be a number, not null.
  // if null, assume closest store is not available or feature flag is disabled
  if (isSameDayEnabled && closestStore && closestStore > 0) {
    // Get "Closest Store" Stock
    const closestStock = getTotalStock(stock, closestStore);
    if (closestStock >= qty) {
      if (canDeliverToday) {
        return AVAILABILITY.NOW;
      }

      return AVAILABILITY.ASAP;
    }
  }

  // Get "All Stores" Stock
  const total = getTotalStock(stock);
  if (total >= qty) {
    if (isSameDayEnabled) return AVAILABILITY.ONE_WEEK;
    else return AVAILABILITY.NOW;
  }

  return AVAILABILITY.TWO_WEEKS;
}

export function getAvailabilityForDelivery(
  erp,
  qty,
  length,
  $sentry,
  closestStore,
  isSameDayEnabled = false
) {
  if (!erp || !erp.stock || qty === undefined) {
    // NOTE: This is not an error necessarily - we're pushing these details
    // to sentry to help debug the issue.
    if (typeof $sentry !== 'undefined') {
      $sentry.captureMessage('Checkout Delivery: Missing Delivery details', {
        level: 'warning',
        extra: {
          erp: erp,
          qty: qty,
          length: length,
          closestStore,
        },
      });
    }

    return AVAILABILITY.DEFAULT;
  }

  // Compare NOW against Same-Day Delivery Cutoff Time
  const canDeliverToday = isBeforeTodayDeliveryCutoff();
  // Timber or Product?
  if (erp.isTally && !erp.isRandomLength) {
    return getTimberLengthAvailability(
      qty,
      length,
      erp.lengthStock,
      closestStore,
      canDeliverToday,
      isSameDayEnabled
    );
  }

  return getStockAvailability(
    qty,
    erp.stock,
    closestStore,
    canDeliverToday,
    isSameDayEnabled
  );
}

export function getAvailabilityForClickCollect(
  erp,
  branchId,
  qty,
  length,
  $sentry
) {
  if (!erp || !erp.stock || !branchId || qty === undefined) {
    // NOTE: This is not an error necessarily - we're pushing these details
    // to sentry to help debug the issue.
    //
    // This is using up too much Sentry resources. Remove for now
    if (typeof $sentry !== 'undefined') {
      // $sentry.captureMessage(
      //   'Checkout Delivery: Missing Click and Collect details',
      //   {
      //     level: 'warning',
      //     extra: {
      //       erp: erp,
      //       branchId: branchId,
      //       qty: qty,
      //       length: length,
      //     },
      //   }
      // );
    }
    return AVAILABILITY.DEFAULT;
  }

  const exactBranchId = branchId.substring(2);

  // Timber Product Availability Logic
  if (erp.isTally && !erp.isRandomLength) {
    const updatedQty = convertQtyToStick(qty, length);

    if (
      erp.lengthStock[exactBranchId] &&
      erp.lengthStock[exactBranchId][length] &&
      erp.lengthStock[exactBranchId][length].stock >= updatedQty
    ) {
      return AVAILABILITY.NOW;
    }

    const totalLengthStock = getTotalLengthStock(erp.lengthStock, length);

    if (totalLengthStock && totalLengthStock > updatedQty) {
      return AVAILABILITY.TWO_DAYS;
    }

    return AVAILABILITY.TWO_WEEKS;
  }

  // Non-Timber Product Logic
  if (
    erp.stock[exactBranchId] &&
    erp.stock[exactBranchId].availableStock >= qty
  ) {
    return AVAILABILITY.NOW;
  }

  const total = getTotalStock(erp.stock);

  if (total >= qty) {
    return AVAILABILITY.TWO_DAYS;
  }

  return AVAILABILITY.TWO_WEEKS;
}

/** Format A Credit Card Input
 * @param {string} cc - the credit card number to format
 * @returns {string} - the formatted string
 */
export function formatCreditCard(cc) {
  return cc.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
}

export function getDefaultForm() {
  return {
    total: undefined,
    cart: {
      items: [],
    },
    delivery: undefined,
  };
}

export function getBranchIdFromCompanyId(companyId) {
  if (!companyId) {
    return undefined;
  }

  const split = companyId.split('-');

  if (split.length !== 2) {
    return undefined;
  }

  return split[1];
}

export function mapItemUomToCartUom(uom) {
  if (uom === STICK) {
    return LINEAL_METER;
  }

  return uom;
}

export function mapItemCfToCartCf(uom, conversionFactor) {
  if (uom === STICK) {
    return LINEAL_METER_CONVERSION_FACTOR;
  }

  return conversionFactor;
}

export function mapDataToCart(items) {
  let cart = {
    items: [],
  };

  if (!items) {
    return cart;
  }

  let cartItems = [];

  for (const item of Object.values(items)) {
    const variation = item.variation;
    const pricing = item.pricing;

    let newItem = {};

    newItem.variationId = variation?.id;
    newItem.flags = variation?.flags;
    newItem.attributes = variation?.attributes;

    newItem.id = item.productId;
    newItem.sku = item.erpId;
    newItem.cf = mapItemCfToCartCf(item.uom, item.conversionFactor);
    newItem.uom = mapItemUomToCartUom(item.uom);
    newItem.qty = item.qty;

    if (pricing?.hasDiscount) {
      newItem.lineTotal = pricing.discount.linePrice ?? undefined;
      newItem.unitPrice = pricing.discount.unitPrice ?? undefined;
    } else {
      newItem.lineTotal = pricing?.linePrice ?? undefined;
      newItem.unitPrice = pricing?.unitPrice ?? undefined;
    }

    cartItems.push(newItem);
  }

  cart.items = cartItems;

  return cart;
}

export function mapDataToDeliveryForClickCollect(checkout) {
  if (!checkout || !Object.keys(checkout).length) {
    return undefined;
  }

  const delivery = {};

  if (checkout && checkout.delivery && checkout.delivery.type) {
    delivery.type = checkout.delivery.type;
  }

  let details = {
    instructions: '',
    date: '',
  };

  if (checkout?.delivery?.details?.deliveryDate) {
    details.date = checkout.delivery.details.deliveryDate;
  }

  if (checkout && checkout.delivery && checkout.delivery.branchId) {
    details.branchId = getBranchIdFromCompanyId(checkout.delivery.branchId);
  }

  if (checkout?.delivery?.details?.instructions) {
    details.instructions = checkout.delivery.details.instructions;
  }

  if (
    checkout &&
    checkout.contactDetails &&
    checkout.contactDetails.deliveryDetails &&
    checkout.contactDetails.deliveryDetails.useBillingDetails
  ) {
    details.contactDetails = {
      company: checkout.contactDetails.billingDetails.company,
      email: checkout.contactDetails.billingDetails.email,
      contactName: checkout.contactDetails.billingDetails.contactName,
      phone: checkout.contactDetails.billingDetails.phone,
    };
  } else if (
    checkout.contactDetails &&
    checkout.contactDetails.deliveryDetails
  ) {
    details.contactDetails = {
      company: checkout.contactDetails.deliveryDetails.company,
      email: checkout.contactDetails.deliveryDetails.email,
      contactName: checkout.contactDetails.deliveryDetails.contactName,
      phone: checkout.contactDetails.deliveryDetails.phone,
    };
  }
  delivery.details = details;

  return delivery;
}

export function getVehicleTotal(checkout) {
  if (
    !checkout ||
    !checkout.delivery ||
    !checkout.delivery.details ||
    !checkout.delivery.details.deliveryTotal
  ) {
    return 0;
  }

  return checkout.delivery.details.deliveryTotal;
}

export function mapDataToDeliveryForDelivery(checkout) {
  if (!checkout || !Object.keys(checkout).length) {
    return undefined;
  }

  const delivery = {
    details: {},
  };

  if (checkout.delivery && checkout.delivery.type) {
    delivery.type = checkout.delivery.type;
  }

  if (checkout.delivery.details && checkout.delivery.details.address) {
    delivery.details = {
      deliveryTotal: getVehicleTotal(checkout),
      time: checkout.delivery.details.deliveryTime,
      date: checkout.delivery.details.deliveryDate,
      instructions: checkout.delivery.details.instructions,
      vehicles: checkout.delivery.details.vehicles,
      craneRequired: checkout.delivery.details.craneRequired,
    };

    delivery.details.address = {
      line1: checkout.delivery.details.address.line1,
      line2: checkout.delivery.details.address.line2,
      suburb: checkout.delivery.details.address.suburb,
      postcode: checkout.delivery.details.address.postcode,
      state: checkout.delivery.details.address.state,
      manuallyEntered: checkout.delivery.details.address.isManual,
    };
  }

  if (
    checkout.contactDetails &&
    checkout.contactDetails.deliveryDetails &&
    checkout.contactDetails.deliveryDetails.useBillingDetails
  ) {
    delivery.details.contactDetails = {
      company: checkout.contactDetails.billingDetails.company,
      email: checkout.contactDetails.billingDetails.email,
      contactName: checkout.contactDetails.billingDetails.contactName,
      phone: checkout.contactDetails.billingDetails.phone,
    };
  } else if (
    checkout.contactDetails &&
    checkout.contactDetails.deliveryDetails
  ) {
    delivery.details.contactDetails = {
      company: checkout.contactDetails.deliveryDetails.company,
      email: checkout.contactDetails.deliveryDetails.email,
      contactName: checkout.contactDetails.deliveryDetails.contactName,
      phone: checkout.contactDetails.deliveryDetails.phone,
    };
  }

  return delivery;
}

export function mapDataToBillingForDelivery(checkout) {
  if (!checkout || !Object.keys(checkout).length) {
    return undefined;
  }

  const billing = {};

  if (
    checkout.delivery &&
    checkout.delivery.details &&
    checkout.delivery.details.address &&
    checkout.contactDetails.billingDetails.useDeliveryAdd
  ) {
    const address = {
      line1: checkout.delivery.details.address.line1,
      line2: checkout.delivery.details.address.line2,
      suburb: checkout.delivery.details.address.suburb,
      postcode: checkout.delivery.details.address.postcode,
      state: checkout.delivery.details.address.state,
      manuallyEntered: checkout.delivery.details.address.isManual,
    };
    billing.address = address;
  } else if (
    checkout.contactDetails &&
    checkout.contactDetails.billingDetails &&
    checkout.contactDetails.billingDetails.address
  ) {
    const address = {
      line1: checkout.contactDetails.billingDetails.address.line1,
      line2: checkout.contactDetails.billingDetails.address.line2,
      suburb: checkout.contactDetails.billingDetails.address.suburb,
      postcode: checkout.contactDetails.billingDetails.address.postcode,
      state: checkout.contactDetails.billingDetails.address.state,
      manuallyEntered: checkout.contactDetails.billingDetails.address.isManual,
    };
    billing.address = address;
  }

  if (checkout.contactDetails && checkout.contactDetails.billingDetails) {
    const contactDetails = {
      company: checkout.contactDetails.billingDetails.company,
      email: checkout.contactDetails.billingDetails.email,
      contactName: checkout.contactDetails.billingDetails.contactName,
      phone: checkout.contactDetails.billingDetails.phone,
    };

    billing.contactDetails = contactDetails;
  }

  return billing;
}

export function mapDataToBillingForClickCollect(checkout, branch) {
  if (!checkout || !Object.keys(checkout).length) {
    return undefined;
  }

  const billing = {};

  if (branch && branch.address) {
    const address = {
      line1: branch.address.lineOne,
      line2: '',
      suburb: branch.address.suburb,
      postcode: branch.address.postcode,
      state: branch.address.state,
      manuallyEntered: false,
    };

    billing.address = address;
  }

  if (checkout.contactDetails && checkout.contactDetails.billingDetails) {
    const contactDetails = {
      company: checkout.contactDetails.billingDetails.company,
      email: checkout.contactDetails.billingDetails.email,
      contactName: checkout.contactDetails.billingDetails.contactName,
      phone: checkout.contactDetails.billingDetails.phone,
    };

    billing.contactDetails = contactDetails;
  }

  return billing;
}

// National Delivery
export function mapDataToDeliveryForNationalOrUberDelivery(checkout) {
  if (!checkout || !Object.keys(checkout).length) {
    return undefined;
  }

  const delivery = {
    details: {},
  };

  if (checkout.delivery && checkout.delivery.type) {
    delivery.type = checkout.delivery.type;
  }

  if (checkout.delivery.details && checkout.delivery.details.address) {
    // Updated to only send total and change vehicles to PARCELS
    delivery.details = {
      deliveryTotal: getVehicleTotal(checkout),
      instructions: checkout.delivery.details.instructions,
      vehicles: checkout.delivery.details.vehicles,
      craneRequired: checkout.delivery.details.craneRequired,
    };

    delivery.details.address = {
      line1: checkout.delivery.details.address.line1,
      line2: checkout.delivery.details.address.line2,
      suburb: checkout.delivery.details.address.suburb,
      postcode: checkout.delivery.details.address.postcode,
      state: checkout.delivery.details.address.state,
      country: checkout.delivery.details.address.country ?? 'AU',
      manuallyEntered: checkout.delivery.details.address.isManual,
    };

    if (delivery.type === DELIVERY_TYPE.UBER_DELIVERY) {
      delivery.details.branchId =
        checkout.delivery.details.uberDeliveryBranchId;
    }
  }

  if (
    checkout.contactDetails &&
    checkout.contactDetails.deliveryDetails &&
    checkout.contactDetails.deliveryDetails.useBillingDetails
  ) {
    delivery.details.contactDetails = {
      company: checkout.contactDetails.billingDetails.company,
      email: checkout.contactDetails.billingDetails.email,
      contactName: checkout.contactDetails.billingDetails.contactName,
      phone: checkout.contactDetails.billingDetails.phone,
    };
  } else if (
    checkout.contactDetails &&
    checkout.contactDetails.deliveryDetails
  ) {
    delivery.details.contactDetails = {
      company: checkout.contactDetails.deliveryDetails.company,
      email: checkout.contactDetails.deliveryDetails.email,
      contactName: checkout.contactDetails.deliveryDetails.contactName,
      phone: checkout.contactDetails.deliveryDetails.phone,
    };
  }

  return delivery;
}

export function mapDataToBillingForNationalOrUberDelivery(checkout) {
  if (!checkout || !Object.keys(checkout).length) {
    return undefined;
  }

  const billing = {};

  if (
    checkout.delivery &&
    checkout.delivery.details &&
    checkout.delivery.details.address &&
    checkout.contactDetails.billingDetails.useDeliveryAdd
  ) {
    const address = {
      line1: checkout.delivery.details.address.line1,
      line2: checkout.delivery.details.address.line2,
      suburb: checkout.delivery.details.address.suburb,
      postcode: checkout.delivery.details.address.postcode,
      state: checkout.delivery.details.address.state,
      country: checkout.delivery.details.address.country ?? 'AU',
      manuallyEntered: checkout.delivery.details.address.isManual,
    };

    billing.address = address;
  } else if (
    checkout.contactDetails &&
    checkout.contactDetails.billingDetails &&
    checkout.contactDetails.billingDetails.address
  ) {
    const address = {
      line1: checkout.contactDetails.billingDetails.address.line1,
      line2: checkout.contactDetails.billingDetails.address.line2,
      suburb: checkout.contactDetails.billingDetails.address.suburb,
      postcode: checkout.contactDetails.billingDetails.address.postcode,
      state: checkout.contactDetails.billingDetails.address.state,
      country: checkout.delivery.details.address.country ?? 'AU',
      manuallyEntered: checkout.contactDetails.billingDetails.address.isManual,
    };
    billing.address = address;
  }

  if (checkout.contactDetails && checkout.contactDetails.billingDetails) {
    const contactDetails = {
      company: checkout.contactDetails.billingDetails.company,
      email: checkout.contactDetails.billingDetails.email,
      contactName: checkout.contactDetails.billingDetails.contactName,
      phone: checkout.contactDetails.billingDetails.phone,
    };

    billing.contactDetails = contactDetails;
  }

  return billing;
}

export function mapDataToOrderForm(branch, total, items, checkout) {
  let form = getDefaultForm();

  form.total = round(total + getVehicleTotal(checkout));
  form.cart = mapDataToCart(items);
  form.details = checkout.orderDetails;

  if (checkout.delivery.type === DELIVERY_TYPE.CLICK_COLLECT) {
    form.delivery = mapDataToDeliveryForClickCollect(checkout);
    form.billing = mapDataToBillingForClickCollect(checkout, branch);
  } else if (checkout.delivery.type === DELIVERY_TYPE.DELIVERY) {
    form.delivery = mapDataToDeliveryForDelivery(checkout);
    form.billing = mapDataToBillingForDelivery(checkout);
  } else if (
    checkout.delivery.type === DELIVERY_TYPE.NATIONAL_DELIVERY ||
    checkout.delivery.type === DELIVERY_TYPE.INTERNATIONAL_DELIVERY ||
    checkout.delivery.type === DELIVERY_TYPE.UBER_DELIVERY
  ) {
    form.delivery = mapDataToDeliveryForNationalOrUberDelivery(checkout);
    form.billing = mapDataToBillingForNationalOrUberDelivery(checkout);
  }

  return form;
}

export function getVariationIdByIndexFromOrderForm(order, index) {
  if (!order || !order.cart || !order.cart.items) {
    return undefined;
  }

  if (!order.cart.items[index]) {
    return undefined;
  }

  return order.cart.items[index].variationId;
}

export function mapBranchText(branch) {
  if (!branch) {
    return undefined;
  }

  let text = '';

  text += branch.name;
  text += ' - ';
  text += branch.address.lineOne;
  text += ' ';
  text += branch.address.suburb;
  text += ' ';
  text += branch.address.postcode;

  return text;
}

export function mapBranchChoices(branchList) {
  if (!branchList) {
    return [];
  }

  let choices = branchList
    .filter(branch => {
      return branch.pickupAvailable;
    })
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(branch => {
      return {
        text: mapBranchText(branch),
        value: branch.id,
      };
    });

  choices.unshift({
    text: 'Please select',
    value: undefined,
  });

  return choices;
}

/**
 * Converts a phone number string into the +61 400 000 000
 * mobile phone number format
 * @param {string} mobileNumber The original mobile phone
 * number
 * @returns {string} The cleaned mobile phone number
 */
export function cleanMobileNumber(mobileNumber) {
  if (!mobileNumber || !mobileNumber.length) {
    return '';
  }
  let cleanNumber = mobileNumber.replace(/[-&/\\#,+()$~%.'":!*?<>{}@^_ ]/g, '');

  const string = '^61';
  const regex = new RegExp(string);

  if (regex.test(cleanNumber)) {
    cleanNumber = cleanNumber.replace('61', '0');
  }
  return cleanNumber;
}

/**
 * Converts a Frameworks Address into an address object that
 * can be consumed by a UiAddressText component
 * @param {Object} rawAddress Object containing Frameworks Address data
 * @returns {Object} Formatted Address Object
 */
export function convertFWAddress(rawAddress) {
  if (
    !rawAddress ||
    !rawAddress.address ||
    !rawAddress.suburb ||
    !rawAddress.postcode ||
    !rawAddress.state
  ) {
    return {
      line1: '',
      line2: '',
      suburb: '',
      postcode: '',
      state: '',
      formatted: '',
      isManual: false,
    };
  }

  return {
    line1: rawAddress?.address,
    line2: '',
    suburb: rawAddress?.suburb,
    postcode: rawAddress?.postcode,
    state: rawAddress?.state,
    formatted: `${rawAddress?.address}, ${rawAddress?.suburb} ${rawAddress?.state} ${rawAddress?.postcode}, Australia`,
    isManual: false,
  };
}

/**
 * Converts a API delivery type (e.g. ute, box_5) into a customer facing
 * delivery type
 *
 * @param {string} apiType API Delivery type
 * @param {boolean} isINTLdelivery International Shipping is being used
 * @return {string} One of the four allowed customer delivery types
 */
export function customerDeliveryType(apiType, isINTLdelivery) {
  if (apiType === 'parcel' || apiType.includes('box')) {
    if (isINTLdelivery) {
      return 'intl_parcel';
    }

    return 'parcel';
  }

  return apiType;
}
