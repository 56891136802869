//
//
//
//
//
//
//
//
//

import ArrowLeft from '~/static/svg/arrow-left.svg';

export default {
  name: 'login-popup-header',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    ArrowLeft,
  },
  methods: {
    closePopup() {
      this.$emit('closePopup');
    },
  },
};
