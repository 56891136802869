import Vue from 'vue';

if (process.env.APP_ENV === 'production') {
  // Disable development tools
  Vue.config.devtools = false;
  Vue.config.debug = false;
  // Disable performance tracking
  Vue.config.performance = false;
  // Disable warnings
  Vue.config.productionTip = false;

  Vue.config.silent = true;

  // Disable error handler warnings
  Vue.config.warnHandler = () => null;

  // Prevent component state inspection
  Vue.config.errorHandler = () => null;
}

// Optional: Add a global error handler for production
if (process.env.APP_ENV === 'production') {
  window.onerror = function() {
    return true; // Prevents error from showing in console
  };
}
