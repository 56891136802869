const USERS_URL = '/users/';

/*
Some handlers, particularly resets, use HTTP error codes correctly.

This means error codes, e.g. 400, shouldn't throw an error. Rather they should
let the calling code handle the error properly and not smother the error.
*/
const resetValidateStatus = status => status >= 200 && status < 500;

// Reusable API request function
async function makeApiRequest($axios, endpoint, payload) {
  const url = USERS_URL + endpoint;

  try {
    const response = await $axios.post(url, payload);

    return response?.data?.data || undefined;
  } catch (e) {
    if (e.response?.status === 401) {
      return { error: true, msg: 'unauthorised' };
    }
    return undefined;
  }
}

export default {
  async checkRememberMe($axios, payload) {
    const url = USERS_URL + 'auth/login/remember';

    let response;
    try {
      response = await $axios.post(url, payload);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }
      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    return response.data.data;
  },
  async magicLinkAuthenticateLogin($axios, payload) {
    const url = USERS_URL + 'auth/login/magiclink';

    let response;
    try {
      response = await $axios.post(url, payload);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }

      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    return response.data.data;
  },
  async authenticate($axios, payload) {
    const url = USERS_URL + 'auth/login';

    let response;
    try {
      response = await $axios.post(url, payload);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }

      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    return response.data.data;
  },
  async validateCompany($axios, payload) {
    return makeApiRequest($axios, 'auth/company', payload);
  },

  async switchCompany($axios, payload) {
    return makeApiRequest($axios, 'auth/switchCustomer', payload);
  },
  async validateTwoFaCode($axios, payload) {
    const url = USERS_URL + 'auth/2fa';

    let response;
    try {
      response = await $axios.post(url, payload);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: (e.response.data && e.response.data.msg) || 'unauthorised',
        };
      }

      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    return response.data.data;
  },
  async resendTwoFaCode($axios, token) {
    const url = USERS_URL + 'auth/2fa/resend';

    let response;
    try {
      response = await $axios.post(
        url,
        { token },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data;
  },
  async getUserDetails($axios) {
    const url = USERS_URL + 'details';

    let response;
    try {
      response = await $axios.get(url);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }

      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    return response.data.data;
  },
  async getUserByEmail($axios, params) {
    const url = USERS_URL + 'details/email';

    let response;

    try {
      response = await $axios.get(url, { params });
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }

      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    return response.data.data;
  },
  async createNewGuestUser($axios, params) {
    const url = USERS_URL + 'create/user';
    let response;

    try {
      response = await $axios.post(url, params);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }

      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    const responseData = response.data;

    if (responseData.error) {
      return undefined;
    }

    return responseData.data;
  },
  async createNewGuestUserContact($axios, params) {
    const url = USERS_URL + 'create/user/contact';
    let response;

    try {
      response = await $axios.post(url, params);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }

      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    const responseData = response.data;

    if (responseData.error) {
      return undefined;
    }

    return responseData.data;
  },
  async createNewEcommUser($axios, params) {
    const url = USERS_URL + 'create/user/ecomm';
    let response;

    try {
      response = await $axios.post(url, params);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        return {
          error: true,
          msg: 'unauthorised',
        };
      }

      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    const responseData = response.data;

    if (responseData.error) {
      return undefined;
    }

    return responseData.data;
  },
  async logout($axios) {
    const url = USERS_URL + 'auth/logout';

    let response;
    try {
      response = await $axios.post(url);
    } catch (e) {
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data.data;
  },
  async refreshToken($axios) {
    const url = USERS_URL + 'auth/refresh';
    let response;
    try {
      response = await $axios.post(url);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        console.log('!!! CATCH LOGGED IN USER CANNOT BE REFRESHED !!!');
        return { error: 'unauthorised' };
      }
      return undefined;
    }

    if (!response || !response.data || !response.data.data) {
      return undefined;
    }

    return response.data.data;
  },
  async requestReset($axios, email) {
    const url = USERS_URL + 'resetpass/request';

    let response;
    try {
      response = await $axios.post(
        url,
        { email },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data;
  },
  async validateReset($axios, token) {
    const url = USERS_URL + 'resetpass/validate';

    let response;
    try {
      response = await $axios.post(
        url,
        { token },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data;
  },
  async validateMagicLink($axios, token, id) {
    const url = USERS_URL + 'magiclink/validate';

    let response;

    try {
      response = await $axios.post(
        url,
        { token, id },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data;
  },
  async disableQuoteReminder($axios, transactionNo, optOut) {
    const url = USERS_URL + 'quote-reminder/disable';

    let response;

    try {
      response = await $axios.post(
        url,
        { transactionNo, optOut },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data;
  },
  async getMagicLinkStatus($axios, id) {
    const url = USERS_URL + 'magiclink/status';

    let response;

    try {
      response = await $axios.post(url, { id });
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data;
  },
  async requestMagicLink($axios, email, rememberMe) {
    const url = USERS_URL + 'magiclink/request';

    let response;

    try {
      response = await $axios.post(url, { email });
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    if (rememberMe) {
      localStorage.setItem('user_remember_me_email', email);
    }

    return response.data;
  },
  async resetPassword($axios, token, password) {
    const url = USERS_URL + 'resetpass/set';

    let response;
    try {
      response = await $axios.post(
        url,
        {
          token,
          password,
        },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data;
  },
  async getStatementDetails($axios) {
    const url = USERS_URL + 'details/statement';

    let response;
    try {
      response = await $axios.get(url);
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data.data;
  },
  async getBalanceDetails($axios) {
    const url = USERS_URL + 'details/balances';

    let response;
    try {
      response = await $axios.get(url);
    } catch (e) {
      console.log(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data.data;
  },
  async getCustomerContacts($axios) {
    const url = USERS_URL + 'customer/list-contacts';

    let response;
    try {
      response = await $axios.get(url);
    } catch (e) {
      console.error(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data.data;
  },
  async updateCustomerContactPermissions($axios, payload) {
    const url = USERS_URL + 'contact/update-permissions';

    let response;
    try {
      response = await $axios.post(
        url,
        { contact: payload },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.error(e);
      return undefined;
    }

    if (!response || !response.data) {
      return undefined;
    }

    return response.data.data;
  },
  async createCustomerContactPermissions($axios, payload) {
    const url = USERS_URL + 'create/user/contact-and-permissions';

    let response;
    try {
      response = await $axios.post(
        url,
        { contact: payload },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.error(e);
      return {
        success: false,
      };
    }

    if (!response || !response.data) {
      return {
        success: false,
      };
    }

    return response.data.data;
  },
  async removeCustomerContactPermissions($axios, payload) {
    const url = USERS_URL + 'contact/remove-permissions';
    let response;
    try {
      response = await $axios.post(
        url,
        { contact: payload },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.error(e);
      return {
        success: false,
      };
    }

    if (!response || !response.data) {
      return {
        success: false,
      };
    }

    return response.data.data;
  },
  async updateCustomerSettings($axios, payload) {
    const url = USERS_URL + 'customer/settings';
    let response;
    try {
      response = await $axios.post(
        url,
        { settings: payload },
        { validateStatus: resetValidateStatus }
      );
    } catch (e) {
      console.error(e);
      return {
        success: false,
      };
    }

    if (!response || !response.data) {
      return {
        success: false,
      };
    }

    return response.data.data;
  },
};
