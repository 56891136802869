//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import OverviewSvg from '~/static/svg/overview.svg';
import BalancesSvg from '~/static/svg/my-balances.svg';
import PayMyAccountSvg from '~/static/svg/my-pay-my-account.svg';
import StatementsSvg from '~/static/svg/my-statements.svg';
import InvoicesSvg from '~/static/svg/my-invoices.svg';
import OrdersSvg from '~/static/svg/my-orders.svg';
import QuotesSvg from '~/static/svg/quotes.svg';
import AccountManagerSvg from '~/static/svg/my-account-manager.svg';
import EInvoiceSvg from '~/static/svg/e-invoice.svg';
import LogoutSvg from '~/static/svg/logout.svg';
import ArrowRightSvg from '~/static/svg/arrow-right--no-fill.svg';
import usersPermissions from '~/mixins/usersPermissions';

export default {
  mixins: [usersPermissions],
  components: {
    OverviewSvg,
    BalancesSvg,
    PayMyAccountSvg,
    StatementsSvg,
    InvoicesSvg,
    OrdersSvg,
    QuotesSvg,
    AccountManagerSvg,
    LogoutSvg,
    ArrowRightSvg,
    EInvoiceSvg,
  },
  props: {
    showOnMobile: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'dashboard',
    },
  },
  data() {
    return {
      hasBalancesPage: false,
      menu_items: {
        admin_permissions: {
          to: this.$t('my_account.admin_permissions.sidebar_menu.to'),
          label: this.$t('my_account.admin_permissions.sidebar_menu.label'),
        },
      },
    };
  },
  methods: {
    ...mapGetters({
      getHasCredit: 'users/getHasCredit',
    }),
    handleClick() {
      if (this.mode === 'login') {
        this.$emit('closePopup');
      }
    },
  },
  mounted() {
    this.hasBalancesPage = this.getHasCredit();
  },
  computed: {
    sidebarClass() {
      return {
        'sidebar-dashboard': this.mode === 'dashboard',
        'login-popup-qlinks-wrapper': this.mode === 'login',
      };
    },
    ...mapGetters({
      getIsLoggedIn: 'users/getIsLoggedIn',
    }),
    quickLinksTitle() {
      return this.getIsLoggedIn
        ? this.$t('general.logged_in_popup.quick_links_header')
        : this.$t('general.login_popup.quick_links_header');
    },
  },
};
