import { render, staticRenderFns } from "./loginPopupHeader.vue?vue&type=template&id=38682d9c&scoped=true&"
import script from "./loginPopupHeader.vue?vue&type=script&lang=js&"
export * from "./loginPopupHeader.vue?vue&type=script&lang=js&"
import style0 from "./loginPopupHeader.vue?vue&type=style&index=0&id=38682d9c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38682d9c",
  null
  
)

export default component.exports