import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      validPermissionRules: [
        'viewCreditLimit',
        'viewOrders',
        'viewQuotes',
        'viewAndPayInvoices',
        'showCustomerPrice',
        'placeOnlineOrders',
        'approveQuotes',
      ],
    };
  },
  methods: {
    userContactHasPermission(rule) {
      if (!this.isUserLoggedIn) return true;

      // if rule is ADMIN - check if user has admin access
      if (rule === 'ADMIN_CHECK') {
        return this.canViewAdminPermissions;
      }

      // Is Admin Permissions Feature Flag Enabled?
      if (!this.isAdminPermissionsEnabled) {
        return true;
      }

      // Check Pass Rule is valid
      if (!this.validPermissionRules.includes(rule)) {
        console.error('### User Permissions Error ###');
        console.error(`Submitted Rule "${rule}" is not a valid rule`);
        return true;
      }

      // check if the passed rule actually exists in stored user data
      if (this.userContact?.permissions?.rules) {
        const rules = this.userContact?.permissions?.rules ?? {};
        if (!Object.prototype.hasOwnProperty.call(rules, rule)) {
          console.error('### User Permissions Error ###');
          console.error(`Submitted Rule "${rule}" does not exist in userdata`);
          return true;
        }

        // specific rule value exists, return the boolean value (true/false)
        return this.userContact.permissions.rules[rule] === true;
      }

      return true;
    },
  },
  computed: {
    ...mapGetters({
      getContact: 'users/getContact',
    }),
    isUserLoggedIn() {
      return this.$store.getters['users/getIsLoggedIn'];
    },
    userContact() {
      if (!this.isUserLoggedIn) {
        return undefined;
      }

      return this.getContact;
    },
    isAdminPermissionsEnabled() {
      return this.$config.featureToggle.toggles.enableAdminPermissions ?? false;
    },
    isUserAdminRole() {
      return this.$store.getters['users/isCustomerContactRoleAdmin'] ?? false;
    },
    canViewAdminPermissions() {
      return this.isAdminPermissionsEnabled && this.isUserAdminRole;
    },
  },
};
