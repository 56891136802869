import Vue from 'vue';
import { DELIVERY_TYPE } from '~/util/checkout';
import Persist from '~/util/persist';
import Storage from '~/util/storage';

import { version } from '../package.json';

import { round } from '../util/helper';

const storage = process.client ? new Storage(window.localStorage) : undefined;
const persist = new Persist('checkout', storage, version, process.client, {
  isExpirable: false,
});

export const getDefaultDeliveryDetails = () => {
  return {
    vehicles: [],
    craneRequired: false,
    deliveryDate: '',
    deliveryTime: '',
    instructions: '',
    address: {},
  };
};

export const getDefault = () => {
  return {
    isHydrated: false,
    contactDetails: {
      billingDetails: {
        contactName: '',
        company: '',
        phone: '',
        email: '',
        useDeliveryAdd: true,
        address: undefined,
      },
      deliveryDetails: {
        contactName: '',
        company: '',
        phone: '',
        email: '',
        useBillingDetails: true,
      },
    },
    delivery: {
      branchId: undefined,
      type: DELIVERY_TYPE.DELIVERY,
      details: getDefaultDeliveryDetails(),
    },
    address: {
      line1: '',
      line2: '',
      suburb: '',
      postcode: '',
      state: '',
      country: '',
      city: '',
      formatted: '',
      isManual: false,
    },
    orderDetails: {
      customerRefNo: '',
    },
    fwDataCached: false,
    agreeTermsAndConditions: false,
    agreeMarketingCommunication: false,
  };
};

export const state = () => getDefault();

export const getters = {
  getCheckout: state => {
    return state;
  },
  getDeliveryType: state => {
    return state.delivery.type;
  },
  getAddress: state => {
    return state.address;
  },
  getDeliveryAddress: state => {
    return state.delivery.details.address;
  },
  getDeliveryDate: state => {
    return state.delivery?.details?.deliveryDate;
  },
  getDeliveryTime: state => {
    return state.delivery?.details?.deliveryTime;
  },
  getDeliveryBranchId: state => {
    return state.delivery.branchId;
  },
  getDeliveryInfo: state => {
    return state.delivery.details;
  },
  getContactDetails: state => {
    return state.contactDetails;
  },
  getBillingDetails: state => {
    return state.contactDetails.billingDetails;
  },
  getDeliveryDetails: state => {
    return state.contactDetails.deliveryDetails;
  },
  getOrderDetails: state => {
    return state.orderDetails;
  },
  isFwDataCached: state => {
    return state.fwDataCached;
  },
  getDeliverySubtotal: state => {
    const amount = state.delivery?.details?.deliveryTotal ?? 0;

    return round(amount);
  },
  getDeliveryGst: state => {
    // let amount = 0;
    // const vehicles = state.delivery.details.vehicles;

    // Object.values(vehicles).forEach(vehicle => {
    //   const pricing = vehicle.pricing;

    //   if (pricing && pricing.incGst && pricing.lineGst) {
    //     amount += pricing.lineGst;
    //   }
    // });

    const deliveryAmount = state.delivery?.details?.deliveryTotal ?? 0;
    let deliveryGST = deliveryAmount / 11;

    return round(deliveryGST);
  },
  getDeliveryTotal: state => {
    // let amount = 0;

    // const vehicles = state.delivery.details.vehicles;

    // Object.values(vehicles).forEach(vehicle => {
    //   const pricing = vehicle.pricing;

    //   if (pricing && pricing.linePrice) {
    //     amount += pricing.linePrice;
    //   }
    // });
    const amount = state.delivery?.details?.deliveryTotal ?? 0;

    return round(amount);
  },
  getAgreeMarketingCommunication: state => {
    return state.agreeMarketingCommunication;
  },
  getAgreeTermsAndConditions: state => {
    return state.agreeTermsAndConditions;
  },
};

export const mutations = {
  isHydrated(state) {
    Vue.set(state, 'isHydrated', true);
  },
  reset(state) {
    const payload = getDefault();
    for (const key in payload) {
      if (key) {
        Vue.set(state, key, payload[key]);
      }
    }

    persist.setItem('checkout', '', state);
  },
  updateAll(state, payload) {
    for (const key in payload) {
      if (key) {
        Vue.set(state, key, payload[key]);
      }
    }
  },
  updateDelivery(state, payload) {
    for (const key in payload) {
      if (key) {
        Vue.set(state.delivery, key, payload[key]);
      }
    }

    persist.setItem('checkout', '', state);
  },
  updateAddress(state, payload) {
    state.address = payload;

    persist.setItem('checkout', '', state);
  },
  updateContactDetailsField(state, payload) {
    const key = payload.fieldName;
    const value = payload.value;

    const keys = key.split('.');

    state.contactDetails[keys[0]][keys[1]] = value;

    persist.setItem('checkout', '', state);
  },
  updateOrderDetailsField(state, payload) {
    const key = payload.fieldName;
    const value = payload.value;

    const keys = key.split('.');

    state[keys[0]][keys[1]] = value;

    persist.setItem('checkout', '', state);
  },
  updateDeliveryInfoField(state, payload) {
    const key = payload.fieldName;
    const value = payload.value;

    state.delivery.details[key] = value;

    persist.setItem('checkout', '', state);
  },
  isFwDataCached(state) {
    state.fwDataCached = true;

    persist.setItem('checkout', '', state);
  },
  clearOrderContactDetails(state) {
    state.contactDetails = {
      billingDetails: {
        contactName: '',
        company: '',
        phone: '',
        email: '',
        useDeliveryAdd: true,
        address: undefined,
      },
      deliveryDetails: {
        contactName: '',
        company: '',
        phone: '',
        email: '',
        useBillingDetails: true,
      },
    };

    state.orderDetails = {
      customerRefNo: '',
    };

    state.fwDataCached = false;

    persist.setItem('checkout', '', state);
  },
  updateAgreeTermsAndConditions(state, payload) {
    state.agreeTermsAndConditions = payload;
  },
  updateAgreeMarketingCommunication(state, payload) {
    state.agreeMarketingCommunication = payload;
  },
  setCustomerDeliveryMethod(state, payload) {    
    Vue.set(state.delivery.details, 'deliveryTotal', payload.deliveryTotal);
    Vue.set(state.delivery.details, 'vehicles', payload.vehicles);
    Vue.set(state.delivery.details, 'craneRequired', payload.craneRequired);
    Vue.set(state.delivery.details, 'uberDeliveryBranchId', payload.uberDeliveryBranchId);
    Vue.set(state.delivery, 'type', payload.deliveryType);
  },
};

export const actions = {
  reset({ commit }) {
    commit('reset');
  },
  async hydrate({ state, commit }) {
    let cached = persist.getItem('checkout', '');

    if (cached && !state.isHydrated) {
      if (
        cached.delivery &&
        cached.delivery.details &&
        cached.delivery.details.deliveryDate
      ) {
        cached.delivery.details.deliveryDate = new Date(
          cached.delivery.details.deliveryDate
        );
      }

      commit('updateAll', cached);
    }

    commit('isHydrated');
  },
  async updateDelivery({ commit }, payload) {
    if (!payload) {
      return undefined;
    }

    if (payload.type === DELIVERY_TYPE.CLICK_COLLECT) {
      payload.details = getDefaultDeliveryDetails();
    }

    commit('updateDelivery', payload);

    return payload;
  },
  async updateAddress({ commit }, payload) {
    if (!payload) {
      return undefined;
    }

    const {
      line1,
      line2,
      state,
      postcode,
      suburb,
      formatted,
      isManual,
    } = payload;

    commit('updateAddress', {
      line1,
      line2,
      state,
      postcode,
      suburb,
      formatted,
      isManual,
    });

    return payload;
  },
  async updateContactDetailsField({ commit }, payload) {
    commit('updateContactDetailsField', payload);
    return true;
  },
  async updateOrderDetailsField({ commit }, payload) {
    commit('updateOrderDetailsField', payload);
    return true;
  },
  async updateDeliveryInfoField({ commit }, payload) {
    commit('updateDeliveryInfoField', payload);
    return true;
  },
  async setFwDataCached({ commit }) {
    commit('isFwDataCached');
    return true;
  },
  async clearOrderContactDetails({ commit }) {
    commit('clearOrderContactDetails');
    return true;
  },
};
